import React, { Fragment, useState, useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { StateContext } from "context/global-state";
import ThreeColumnLayout from "components/layout/three-column-layout";
import Content from "components/layout/content";
import RightPanel from "components/layout/right-panel";
import Tooltip from "components/generic/tooltip/tooltip";
import PortfolioSnapshotModal from "components/modals/portfolio-snapshot-modal";
import InvestmentOptionsWalkthrough from "components/investments/Investment-options-walkthrough";
import "scss/investments/investment-options.scss";

const InvestmentOptions: React.FC = () => {
	const { state } = useContext(StateContext);
	const [prebuildOptions, setPrebuildOptions] = useState(false);
	const [showModal, toggleModal] = useState(false);
	const isEspanol = state.language === "Español";
	const [expandedPanel, toggleExpand] = useState<boolean>(false);
	const lifetimeAccount = [2010, 2015, 2020, 2025, 2030, 2035, 2040, 2045, 2050, 2055, 2065, "Strategic Income"];
	const [filteredArray, setFilteredArray] = useState(lifetimeAccount);
	const memoizedToggleModal = useCallback(() => {
		toggleModal(!showModal);
	}, [showModal]);

	const filterRetirementDropdown = (dropdownValue: number) => {
		switch (dropdownValue) {
			case dropdownValue >= 40 && dropdownValue <= 60 && dropdownValue:
				return setFilteredArray([dropdownValue + 1990, dropdownValue + 1995]);
			case 65:
				return setFilteredArray([2055]);
			case dropdownValue >= 66 && dropdownValue <= 85 && dropdownValue:
				return setFilteredArray([2065]);
			case 100:
				return setFilteredArray([2010, 2015, 2020, "Strategic Income"]);
			default:
				return setFilteredArray(lifetimeAccount);
		}
	};

	return (
		<ThreeColumnLayout>
			{!expandedPanel ? (
				<>
					<Content>
						<h1 className="text-center util-margin-top-0">{isEspanol ? "Pick an investment option" : "Elige una opción de inversión"}</h1>
						<p>
							{isEspanol
								? "There are different ways to move forward, each with different advantages based on how much you understand about investments. You're in control of what's next."
								: "Hay muchas formas de continuar. Cada opción tiene sus ventajas y dependen de cuánto comprendas el mundo de las inversiones. Tú tienes el control de lo que sigue."}
						</p>
						<hr className="dotted" />
						<div id="choose-portfolio">
							<h2 className="text-bold">{isEspanol ? "Choose a pre-built portfolio" : "Elije un portafolio preestablecido"}</h2>
							<div className="util-margin-bottom-50">
								<img src="media/boxes-diagonal.png" alt="Diagonal Boxes" width="100" height="100" className="pull-left" />
								<p>
									{isEspanol
										? "You can explore existing portfolios built by investment professionals and can quickly pick a portfolio based on when you want to retire and/or your risk level depending on your goals."
										: "Puedes explorar los portafolios existentes que ya crearon los profesionales de inversión y puedes elegir un portafolio rápidamente según la fecha en que quieres jubilarte y/o tu nivel de riesgo dependiendo de tus metas."}
								</p>
							</div>
							{!prebuildOptions && (
								<div className="text-center">
									<button onClick={() => setPrebuildOptions(true)} className="btn btn-default util-padding-horz-60" type="button">
										{isEspanol ? "Show my pre-built options" : "Ver mis opciones predeterminadas"}
									</button>
								</div>
							)}
							{prebuildOptions && (
								<>
									<h2 className="text-bold">
										{isEspanol ? (
											<>
												Target My Retirement
												<sup>&reg;</sup>
											</>
										) : (
											<>
												Apuntar a mi jubilación
												<sup>&reg;</sup>
											</>
										)}
									</h2>
									<div className="util-margin-bottom-50">
										<p>
											{isEspanol ? (
												<>
													Target My Retirement is personalized retirement planning advice (like how much to save, where to invest your savings and retirement
													age!) for a <Tooltip classes="btn-link">fee</Tooltip>. You give us and Morningstar Investment Management LLC a little information now
													to get set up. The best part about it? You can be as hands off or hands on as you want. Target My Retirement is powered by Morningstar
													Investment Management, a leader in the investment industry.
												</>
											) : (
												<>
													Target My Retirement es un consejo personalizado para la planificación de la jubilación (como cuánto ahorrar, dónde invertir sus
													ahorros y la edad de jubilación) por una <Tooltip classes="btn-link">tarifa</Tooltip>. nos brinde a nosotros ya Morningstar Investment
													Management LLC un poco de información ahora para configurarlo. ¿La mejor parte de esto? Puede ser tan práctico como desee. Target My
													Retirement está impulsado por Morningstar Investment Management, un líder en la industria de inversiones.
												</>
											)}
										</p>
										<p>
											{isEspanol
												? "And if you're still not sure, the fee will be waived until DD/MM/YYYY."
												: "Y si aún no está seguro, la tarifa no se aplicará hasta el DD/MM/AAAA."}
										</p>
									</div>

									<div className="text-center">
										<Tooltip classes="util-margin-bottom-15">
											<button className="btn-link">
												<strong>{isEspanol ? "Learn More" : "Aprende más"}</strong>
											</button>
										</Tooltip>
									</div>
									<div className="text-center">
										<Link to="/investments/investment-tmr" className="btn btn-default util-padding-horz-60 util-margin-bottom-15">
											{isEspanol ? "I want Target My Retirement" : "Quiero apuntar a mi jubilación"}
										</Link>
									</div>
									<h3>
										{isEspanol ? "Target Date Portfolios" : "Portafolios con fecha objetivo"}
										&nbsp;
										<small>
											<Tooltip classes="btn-link" position="right">
												<i className="fa fa-info-circle"></i>
											</Tooltip>
										</small>
									</h3>
									<form className="text-left form-inline util-margin-bottom-20">
										<div className="form-group">
											<label htmlFor="desired-retirement-age" className="util-margin-right-5">
												{isEspanol ? "Desired Retirement Age:" : "Edad de jubilación deseada:"}
											</label>
											<select
												id="desired-retirement-age"
												className="form-control input-sm"
												onChange={(e) => {
													filterRetirementDropdown(Number(e.target.value));
												}}
											>
												<option value={1}>{isEspanol ? "All" : "Todos"}</option>
												<option value={45}>40-45</option>
												<option value={50}>46-50</option>
												<option value={55}>51-55</option>
												<option value={60}>56-60</option>
												<option value={65}>61-65</option>
												<option value={70}>66-70</option>
												<option value={75}>71-75</option>
												<option value={80}>76-80</option>
												<option value={85}>81-85</option>
												<option value={100}>{isEspanol ? "Retired" : "Jubilado"}</option>
											</select>
										</div>
										<small className="clearfix">{isEspanol ? "Selection may vary based on your goals." : "La selección puede variar según tus metas."}</small>
									</form>
									<div className="row flex-row">
										{filteredArray.map((year) => (
											<Fragment key={`desired-retirement-${year}`}>
												<div className="col-sm-6 flex-col">
													<div className="panel panel-default text-left util-margin-bottom-50">
														<div className="panel-heading text-center">
															Principal LifeTime&nbsp;
															{year} Separate Account
														</div>
														<div className="panel-body text-center">
															<Link to={state.experienced ? "/dashboard" : "/rollovers"} className="btn btn-default btn-block">
																{isEspanol ? "I want this pre-built" : "Quiero la opción preestablecida (en inglés)"}
															</Link>
															<div>
																<Tooltip classes="btn btn-link">{isEspanol ? "Portfolio Snapshot" : "Un vistazo a tu portafolio (en inglés)"}</Tooltip>
															</div>
														</div>
													</div>
												</div>
											</Fragment>
										))}
									</div>
								</>
							)}
							<hr className="dotted" />
							<div className="util-margin-top-30">
								<h2 className="text-bold">{isEspanol ? "Build your own portfolio" : "Construye tu propio portafolio"}</h2>
								<img src="media/boxes-offset.png" alt="Boxes Offset" width="100" height="100" className="pull-left" />
								<p>
									{isEspanol
										? "For those who better understand investment topics or who want more control of which investments, you can create your own portfolio based on your individual goals."
										: "Si quieres entender mejor los temas de inversión o tener más control sobre tus opciones de inversión, crea tu propio portafolio según tus metas personales."}
								</p>
								<div className="text-center util-margin-vert-25">
									<Tooltip classes="btn btn-default btn-lg">{isEspanol ? "I want to build my own" : "Quiero la opción preestablecida (en inglés)"}</Tooltip>
								</div>
							</div>
						</div>
						<hr className="dotted" />
						<div id="quick-option">
							<h2 className="text-bold">{isEspanol ? "Here's the quick option" : "Esta es la opción rápida."}</h2>
							<p>
								{isEspanol
									? "Here's the investment that your organization chose. You can skip this step, but this is where contributions will be invested until you make another selection."
									: "Puedes omitir este paso, pero esta es la inversión preestablecida del plan y es donde se invertirán las contribuciones hasta que elijas otra cosa."}
							</p>
							<div className="panel panel-default text-left util-margin-bottom-50">
								<div className="panel-heading">
									<h2 className="text-white util-margin-20">Principal LifeTime Strategic Income Separate Account</h2>
									<p className="text-bold util-margin-20">Multiple Sub-Advisors</p>
									{!isEspanol && (
										<>
											<span className="util-margin-left-20">
												Este es un portafolio con fecha objetivo.
												<Tooltip classes="btn-link text-white">&nbsp;Más información</Tooltip>
											</span>
										</>
									)}
								</div>
								<div className="panel-body util-padding-40">
									<Link to={state.experienced ? "/dashboard" : "/rollovers"} className="btn btn-default btn-lg">
										{isEspanol ? "Yep, I want this one" : "Sí. Quiero esta opción."}
									</Link>
									<button className="btn btn-link" onClick={() => toggleModal(true)}>
										{isEspanol ? "Portfolio Snapshot" : "Un vistazo a tu portafolio (en inglés)"}
									</button>
								</div>
							</div>
						</div>
						<hr className="dotted" />
						<div id="retiremnet-account">
							<h2 className="text-bold">
								{isEspanol ? (
									<>
										Schwab Personal Choice Retirement Account<sup>&reg;</sup>
									</>
								) : (
									<>
										Cuenta de jubilación Schwab Personal Choice<sup>&reg;</sup>
									</>
								)}
							</h2>
							<p>
								{isEspanol
									? "The Schwab Personal Choice Retirement Account (PCRA) is a brokerage account available to you in your organization’s retirement plan. You’ll typically have access to thousands of investment options available through Schwab PCRA in addition to the plan's designated investment option lineup. The Schwab PCRA gives you more control over your investment decisions so you can tailor your strategy to fit your long-term goals."
									: "La cuenta de jubilación Schwab Personal Choice (PCRA) es una cuenta de corretaje disponible para usted en el plan de jubilación de su organización. Por lo general, tendrá acceso a miles de opciones de inversión disponibles a través de Schwab PCRA además de la lista de opciones de inversión designadas por el plan. Schwab PCRA le brinda más control sobre sus decisiones de inversión para que pueda adaptar su estrategia a sus objetivos a largo plazo."}
							</p>
							<p>
								{isEspanol
									? "Visit principal.com/pcra once you’ve enrolled in your organizations’ retirement plan to get started."
									: "Visite principal.com/pcra una vez que se haya inscrito en el plan de jubilación de su organización para comenzar."}
							</p>
						</div>
						<hr className="dotted" />
						<div className="text-center">
							<Tooltip classes="btn btn-link">
								{isEspanol ? (
									<>
										Explore RetireView<sup>&reg;</sup>&nbsp; Options
									</>
								) : (
									<>
										Explorar las opciones de RetireView
										<sup>&reg;</sup> (en inglés)
									</>
								)}
							</Tooltip>
							<br />
							{isEspanol ? (
								<>
									View
									<Tooltip classes="btn btn-link util-padding-0" position="bottom">
										&nbsp;Frequently Asked Questions&nbsp;
									</Tooltip>
									about RetireView<sup>&reg;</sup>
								</>
							) : (
								<>
									Ver
									<Tooltip classes="btn btn-link util-padding-0" position="bottom">
										&nbsp;las preguntas frecuentes sobre&nbsp;
									</Tooltip>
									RetireView<sup>&reg;</sup>
								</>
							)}
						</div>
						<div className="small util-margin-vert-40">
							<hr />
							<p className="text-bold">
								{isEspanol ? "Investing involves risk, including possible loss of principal" : "Portafolios de asesores de inversión registrados"}
							</p>
							<p>
								{isEspanol ? (
									<>
										<strong>Asset allocation</strong> and diversification does not ensure a profit or protect against a loss.&nbsp;
										<strong>Equity</strong> investment options involve greater risk, including heightened volatility, than fixed-income investment
										options.&nbsp;
										<strong>Fixed-income</strong>&nbsp; investments are subject to interest rate risk; as interest rates rise their value will decline.&nbsp;
										<strong>International and global investing</strong>
										&nbsp; involves greater risks such as currency fluctuations, political/social instability and differing accounting standards. These risks
										are magnified in&nbsp;
										<strong>emerging markets.</strong>
									</>
								) : (
									"La distribución de activos y la diversificación no garantizan una ganancia ni protegen contra pérdidas. Invertir en la bolsa es más riesgoso que invertir en renta fija, y tiene más volatilidad. Las opciones de inversión de renta fija están sujetas a un mayor riesgo y su valor declinará a medida que las tasas de interés aumenten. Las opciones de inversión internacionales y globales involucran riesgos mayores tales como fluctuaciones en los tipos de cambio, inestabilidad política y social y estándares contables diferentes. Estos riesgos se incrementan aún más en mercados emergentes."
								)}
							</p>
							<p>
								{isEspanol
									? "There is no guarantee that a target date investment will provide adequate income at or through retirement."
									: "No hay garantía de que una inversión con fecha objetivo proveerá un ingreso adecuado en el momento de o durante la jubilación."}
							</p>
							<p>
								{isEspanol ? (
									<>
										Insurance products and plan administrative services provided through Principal Life Insurance Co. Principal Funds, Inc. are distributed by
										Principal Funds Distributor, Inc. Securities offered through Principal Securities, Inc., 800-547-7754, member SIPC and/or independent
										broker-dealers. Principal Life, Principal Funds Distributor, Inc. and Principal Securities<sup>&reg;</sup>
										&nbsp; are members of the Principal Financial Group<sup>&reg;</sup>, Des Moines, IA 50392. Certain investment options may not be available
										in all states or U.S. commonwealths.
									</>
								) : (
									<>
										Los productos de seguros y servicios administrativos del plan son provistos a través de Principal Life Insurance Co. Los valores son
										ofrecidos a través de Principal Securities, Inc., (tel: 800-547-7754, para español, oprime el 1), miembro de la SIPC y/o agentes/corredores
										independientes. Principal Life y Principal Securities son miembros de Principal Financial Group<sup>&reg;</sup>, Des Moines, Iowa 50392.
										Algunas opciones de inversión y cláusulas especiales de contrato podrían no estar disponibles en todos los estados o estados libres
										asociados de EE.UU
									</>
								)}
							</p>
						</div>
					</Content>
					<RightPanel>
						{isEspanol ? (
							<>
								<div className="util-margin-vert-15 conversation-content conversation-left-top">Let us walk you through what each option means.</div>
								<div className="text-center">
									<button onClick={() => toggleExpand(true)} className="btn btn-default util-padding-horz-60" type="button">
										Let's go
									</button>
								</div>
								<hr />
								<p className="h5">Take a quiz to find out how you handle investment risk.</p>
								<div className="text-center">
									<Tooltip classes="btn btn-link text-bold" position="bottom">
										Take the quiz
									</Tooltip>
								</div>
								<hr />
								<h3 className="util-margin-top-30">Read and review</h3>
								<img src="media/investing-basics.jpg" alt="Investing Basics" width="100%" />
								<div>
									<Tooltip classes="btn btn-link util-padding-horz-0" position="bottom">
										New to investing, or just need a quick review of the basics?
									</Tooltip>
								</div>
								<hr />
								<h3 className="util-margin-top-30">Watch to feel wiser</h3>
								<img src="media/market-volatility.jpg" alt="Investing Basics" width="100%" />
								<div>
									<Tooltip classes="btn btn-link util-padding-horz-0" position="bottom">
										Coping with market volatility
									</Tooltip>
								</div>
							</>
						) : (
							<>
								<p className="h5">Haz esta prueba para saber qué tipo de inversionista eres.</p>
								<div className="text-center">
									<Tooltip classes="btn btn-link">Evaluación</Tooltip>
								</div>
								<hr />
								<h3>Construye tu futuro</h3>
								<img src="media/coffee.jpeg" alt="Coffee" width="100%" />
								<div>
									<Tooltip classes="btn-link" position="bottom">
										Es hora de decirle “hola” al futuro de tus finanzas
									</Tooltip>
								</div>
							</>
						)}
					</RightPanel>
				</>
			) : (
				<InvestmentOptionsWalkthrough closeWindow={() => toggleExpand(false)} />
			)}
			{showModal && <PortfolioSnapshotModal bindToggle={memoizedToggleModal} />}
		</ThreeColumnLayout>
	);
};

export default InvestmentOptions;

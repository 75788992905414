import React, { useState } from "react";
import { Link } from "react-router-dom";
import ThreeColumnLayout from "components/layout/three-column-layout";
import Content from "components/layout/content";
import RollOversRightPanel from "components/rollovers/rollovers-right-panel";

const HowAboutThis: React.FC = () => {
	const [checkOption, setCheckOption] = useState(false);
	return (
		<ThreeColumnLayout>
			<Content>
				<h1 className="text-center util-margin-top-0">
					How do you want to go about this?
				</h1>
				<hr className="dotted" />
				<div className="table-responsive row util-margin-horz-0">
					<table className="table-bordered col-md-12">
						<tbody>
							<tr>
								<td className="util-padding-10">
									<div className="radio styled-radio">
										<input
											type="radio"
											id="radio-yes"
											name="check-handy"
											onChange={() =>
												setCheckOption(true)
											}
										/>
										<label htmlFor="radio-yes">
											Contact your provider to get a check
										</label>
									</div>
								</td>
							</tr>
							<tr>
								<td className="util-padding-10">
									<div className="radio styled-radio">
										<input
											type="radio"
											id="radio-no"
											name="check-handy"
											onChange={() =>
												setCheckOption(true)
											}
										/>
										<label htmlFor="radio-no">
											Call us and we'll help
										</label>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<hr className="dotted util-margin-vert-40" />
				<div className="text-center util-margin-bottom-40">
					{checkOption ? (
						<Link
							to="/rollovers/get-your-check"
							className="btn btn-primary"
						>
							Next
						</Link>
					) : (
						<button
							type="button"
							className="btn btn-default"
							disabled
						>
							Next
						</button>
					)}
				</div>
			</Content>
			<RollOversRightPanel>
				<p>
					It's up to you, but if you start going down one path and
					change your mind, we're here to help.
				</p>
			</RollOversRightPanel>
		</ThreeColumnLayout>
	);
};

export default HowAboutThis;

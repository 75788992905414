export interface IState {
	experienced: boolean;
	contributionRates: {
		total: number;
		preTax: number;
	};
	annualIncreases: {
		// [annual rate increase, max rate, naming convention]
		preTax: [number, number, string];
		postTax: [number, number, string];
	};
	annualIncreaseDate: {
		month: string;
		day: string;
	};
	income: {
		salary: number;
		payFrequency: number;
		hourly: boolean;
	};
	language: string;
}

export const initialState: IState = {
	experienced: false,
	contributionRates: {
		total: 3,
		preTax: 3
	},
	annualIncreases: {
		preTax: [0, 0, "Pre-tax"],
		postTax: [0, 0, "Roth (after-tax)"]
	},
	annualIncreaseDate: {
		month: "9",
		day: "4"
	},
	income: {
		salary: 45000,
		payFrequency: 26,
		hourly: false
	},
	language: "Español"
};

export const reducer = (state: IState, action: any) => {
	switch (action.type) {
		case "updateExperience":
			return { ...state, experienced: action.payload };
		case "updateContributionRates":
			return { ...state, contributionRates: action.payload };
		case "updateAnnualIncreases":
			return { ...state, annualIncreases: action.payload };
		case "updateAnnualIncreaseDate":
			return { ...state, annualIncreaseDate: action.payload };
		case "updateIncome":
			return { ...state, income: action.payload };
		case "setLanguage":
			return { ...state, language: action.payload };
		default:
			throw new Error("Action type must be defined");
	}
};

import React, { useState, useContext, useEffect, useCallback } from "react";
import ThreeColumnLayout from "components/layout/three-column-layout";
import Content from "components/layout/content";
import ContributionsRightPanel from "components/contributions/contributions-right-panel";
import { StateContext } from "context/global-state";
import { Link } from "react-router-dom";
import Tooltip from "components/generic/tooltip/tooltip";
import ContributionPerPayCheck from "components/layout/contribution-per-paycheck";
import EditSalary from "components/contributions/edit-salary";
import "scss/contributions/set-custom-contribution-rate.scss";

const SetCustomContributionRatePage: React.FC = () => {
	const { state, dispatch } = useContext(StateContext);
	const { total } = state.contributionRates;
	const [leftMargin, shiftMargin] = useState<number>(0);
	const [barIteration, updateBarIteration] = useState<number>(0);
	const [expandedPanel, toggleExpand] = useState<boolean>(false);
	const memoizedToggleExpand = useCallback(() => {
		toggleExpand(!expandedPanel);
	}, [expandedPanel]);

	const barScrollWidth = 20;
	const marginIncrement = 5;
	const maxLimit = 50;
	const minLimit = 0;
	const match = 6;
	const secondMatch = 10;

	// Have context be directly responsible for moving the slider
	useEffect(
		() =>
			shiftMargin(
				total * marginIncrement -
					barScrollWidth * marginIncrement * barIteration
			),
		[total, barIteration]
	);

	// Fires when clicking on the '-' and '+' buttons; responsible for updating bar iteration
	const clickToAdjustRate = (increment: number) => {
		if (increment + total < minLimit || increment + total > maxLimit)
			return;
		if (
			increment + total >
				barIteration * barScrollWidth + barScrollWidth ||
			increment + total < barIteration * barScrollWidth
		)
			updateBarIteration(barIteration + increment);

		dispatch({
			type: "updateContributionRates",
			payload: {
				total: total + increment,
				preTax: total + increment
			}
		});
	};

	// Callback for sliding on mouse and touch events
	const handleSlide = (e: any) => {
		if (e instanceof MouseEvent) e.preventDefault();

		const ref = document.getElementById("slider-boundary");
		const clientRect = ref!.getBoundingClientRect();
		const calcButtonMargin =
			(((e.clientX || e.touches[0].clientX) - clientRect.left) /
				clientRect.width) *
			100;
		const roundedRate =
			Math.round(calcButtonMargin / marginIncrement) +
			barScrollWidth * barIteration;

		if (roundedRate < barIteration * barScrollWidth) return;
		if (
			roundedRate > barIteration * barScrollWidth + barScrollWidth ||
			roundedRate > maxLimit
		)
			return;

		dispatch({
			type: "updateContributionRates",
			payload: {
				total: roundedRate,
				preTax: roundedRate
			}
		});
	};

	// Mouse Event for sliding
	const handleMouseDown = () => {
		window.addEventListener("mousemove", handleSlide);
		window.addEventListener("mouseup", handleMouseUp);
	};

	const handleMouseUp = () => {
		window.removeEventListener("mousemove", handleSlide);
		window.removeEventListener("mouseup", handleMouseUp);
	};

	const greenBackgroundText = () => {
		if (total < match) {
			return "Check that you're contributing enough to get the full match.";
		} else if (total < secondMatch) {
			return `You're making progress-that's great. Studies show that you
					may need to save ${secondMatch}%, plus other contributions throughout
					your career to have enough income in retirement. An annual
					increase may help.`;
		} else {
			return (
				<>
					<strong>Bravo! </strong>Way to make your retirement a
					priority! Your commitment to saving is impressive.
				</>
			);
		}
	};

	return (
		<ThreeColumnLayout>
			{!expandedPanel ? (
				<>
					<Content>
						<h1 className="text-midnight util-margin-top-0 util-margin-bottom-30 text-center animated fadeInDownSmall">
							Set your contribution rate
						</h1>
						<hr className="dotted animated fadeInDownSmall" />
						<div className="row animated fadeInDownSmall">
							<div className="col-sm-8 col-sm-offset-2">
								<div className="slider-wrapper util-margin-30">
									<button
										className="slider-button decrement"
										onClick={() => clickToAdjustRate(-1)}
									>
										–{" "}
										<span className="sr-only">
											Decrement
										</span>
									</button>
									<div
										className="slider noUi-target noUi-ltr noUi-horizontal noUi-background"
										id="slider-boundary"
										style={{
											backgroundImage: `url(media/bg-dash-white.png)`
										}}
									>
										<div className="noUi-base">
											<div
												onMouseDown={() =>
													handleMouseDown()
												}
												onTouchMove={e =>
													handleSlide(e)
												}
												className="noUi-origin"
												style={{
													left: `${leftMargin}%`
												}}
											>
												<div className="noUi-handle noUi-handle-lower">
													<span className="slider-value">
														<em></em>
														{total}
														<small>%</small>
													</span>
												</div>
											</div>
											<label
												className="slider-marker"
												style={{
													left: `${match *
														marginIncrement}%`,
													visibility:
														barIteration === 0
															? "visible"
															: "hidden"
												}}
											>
												<p className="slider-marker-match">
													{`${match}%`}
													<br />
													match
												</p>
											</label>
											<label
												className="slider-marker"
												style={{
													left: `${secondMatch *
														marginIncrement}%`,
													visibility:
														barIteration === 0
															? "visible"
															: "hidden"
												}}
											>
												{`${secondMatch}%`}
											</label>
										</div>
									</div>
									<button
										className="slider-button increment"
										onClick={() => clickToAdjustRate(1)}
									>
										+{" "}
										<span className="sr-only">
											Increment
										</span>
									</button>
								</div>
							</div>
						</div>
						<div className="text-center util-margin-top-10 animated fadeInDownSmall">
							<Tooltip classes="btn btn-link">
								<small>Contribute by dollar amount</small>
							</Tooltip>
						</div>
						<div className="bg-light-green util-border-radius-20 util-padding-30 util-margin-top-10 animated fadeInDownSmall">
							{greenBackgroundText()}
						</div>
						<hr className="dotted animated fadeInDownSmall" />
						<div className="text-center animated fadeInDownSmall">
							<Link
								to="/contributions/allocate-contribution-rate"
								className="btn btn-primary btn-lg util-margin-30"
							>
								Next
							</Link>
						</div>
					</Content>

					<ContributionsRightPanel bindToggle={memoizedToggleExpand}>
						<ContributionPerPayCheck />
					</ContributionsRightPanel>
				</>
			) : (
				<EditSalary bindToggle={memoizedToggleExpand} />
			)}
		</ThreeColumnLayout>
	);
};

export default SetCustomContributionRatePage;

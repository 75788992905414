import React from "react";
import Modal from "components/generic/modal/modal";
import ModalHeader from "components/generic/modal/modal-header";
import ModalBody from "components/generic/modal/modal-body";

const DisclaimerModal: React.FC<{ bindToggle: any }> = ({ bindToggle }) => (
	<Modal bindModal={bindToggle}>
		<ModalHeader bindModal={bindToggle}>
			<h2 className="modal-title">Disclaimer:</h2>
		</ModalHeader>
		<ModalBody>
			<p>
				This assumes you may need about 85 percent of your
				pre-retirement income to maintain your current lifestyle after
				you retire. Based on analysis conducted by the Principal
				Financial Group
				<sup>&reg;</sup>, October 2015. The estimate assumes a 40-year
				span of accumulating savings and the following facts: retirement
				at age 65; a combined individual and plan sponsor contribution
				of 12 percent; Social Security providing 40 percent replacement
				of income; 7 percent annual rate of return; 2.5 percent annual
				inflation; and 3.5 percent annual wage growth over 40 years in
				the workforce. This estimate is based on a goal of replacing
				about 85 percent of salary. The assumed rate of return for the
				analysis is hypothetical and does not guarantee any future
				returns nor represent the return of any particular investment.
				Contributions do not take into account the impact of taxes on
				pre-tax distributions. Individual results will vary.
				Participants should regularly review their savings progress and
				post-retirement needs. Assuming pre-retirement annual gross
				income of $40,000. AON Consulting's 2008 Replacement Ratio Study
				<sup>&trade;</sup>
			</p>
		</ModalBody>
	</Modal>
);

export default DisclaimerModal;

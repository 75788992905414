import React from "react";
import AnimatedMessage from "./animated-message";
import Tooltip from "components/generic/tooltip/tooltip";

const AnimatedPanelWithImage: React.FC<{ panelMessageWithImage: string }> = ({
	panelMessageWithImage
}) => (
	<div className="d-flex-col">
		<AnimatedMessage message={panelMessageWithImage} />
		<div className="conversation-panel">
			<div className="d-flex-row">
				<img
					className="pull-left h-100"
					alt=""
					src="media/boxes-offset.png"
				/>
				<div>
					<div className="panel-heading-dashboard">
						Build your own portfolio
					</div>
					<div className="panel-body">
						<p>
							For those who better understand investment topics or
							who want more control of their investments, you can
							create your own portfolio based on your individual
							goals.
						</p>
					</div>
				</div>
			</div>
			<div className="panel-body text-center">
				<Tooltip position="top" classes="btn btn-default">
					I want to build my own
				</Tooltip>
			</div>
		</div>
	</div>
);

export default AnimatedPanelWithImage;

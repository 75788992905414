import React, { useContext, useState, useCallback } from "react";
import Landing from "components/layout/landing";
import DefaultAltButtonLink from "components/generic/buttons/default-alt-button-link";
import SkipThisStep from "components/modals/skip-this-step";
import { StateContext } from "context/global-state";

const InvestmentsFirstStep: React.FC = () => {
	const { state } = useContext(StateContext);
	const [showModal, toggleModal] = useState<boolean>(false);
	const isEspanol = state.language === "Español";
	const memoizedToggleModal = useCallback(() => {
		toggleModal(!showModal);
	}, [showModal]);
	return (
		<>
			<Landing>
				<img src="media/investments-icon.png" alt="large-icon" />
				<h1>
					{isEspanol ?
						"Let's check out your investment options."
						:
						"Veamos tus opciones de inversión."
					}
				</h1>
				<p>
					{isEspanol ?
						"Each one has different advantages based on how much time you have until your retirement and how you feel about risk."
						:
						"Cada una tiene diferentes ventajas, según cuánto tiempo te quede para jubilarte y cómo te sientes respecto al riesgo."
					}
				</p>
				<div
					className="util-margin-vert-30"
					style={{ display: "inline-block" }}
				>
					<DefaultAltButtonLink route="/investments/quick-option">
						{isEspanol ? "Continue" : "Continuar"}
					</DefaultAltButtonLink>
				</div>
				<button
					className="btn btn-link center-block text-white"
					onClick={() =>
						toggleModal(true)
					}
				>
					{isEspanol ? "Skip this step" : "Omitir este paso"}
				</button>
				{showModal && <SkipThisStep bindToggle={memoizedToggleModal} />}
			</Landing>
		</>
	);
};

export default InvestmentsFirstStep;

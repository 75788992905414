import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import ThreeColumnLayout from "components/layout/three-column-layout";
import Content from "components/layout/content";
import Tooltip from "components/generic/tooltip/tooltip";
import ChatPanel from "components/layout/chat-panel";

const OneLastThing: React.FC = () => {
	const [showPanel, togglePanel] = useState<boolean>(false);
	const memoizedTogglePanel = useCallback(() => {
		togglePanel(!showPanel);
	}, [showPanel]);

	return (
		<ThreeColumnLayout>
			<Content>
				<h1 className="text-center util-margin-top-0">
					One last thing!
				</h1>
				<p>
					It's important to read this because it's making sure you
					understand the move and meeting rules and regulations for
					moving the funds.
				</p>
				<p>Click the button below to let us know if you agree.</p>
				<hr className="dotted util-margin-vert-40" />
				<p>
					I confirm I would like to roll over retirement funds
					<sup>1</sup> from a previous employer's retirement plan or
					IRA. If I do not have an investment election on file or wish
					to make an alternative allocation, I will select investment
					options by logging into Principal.com or calling
					800-547-7754. If I don't make a different investment
					election prior to my rollover funds being received by
					Principal<sup>&reg;</sup>, my funds will be invested based
					on my current investment election. If I do not have a
					current investment election on file, my funds will be
					directed to the plan's investment default option.
				</p>
				<p>
					I certify that this rollover contains no funds from a
					hardship withdrawal, no excess contribution amounts, and no
					funds subject to a required minimum distribution.
				</p>
				<p>
					I certify that no part of this rollover is part of an
					inherited IRA, SIMPLE IRA within 2 years of the first
					contribution, or Roth IRA.
				</p>
				<p>
					I certify that, if the funds are coming from a former
					employer's plan, I have verified that the plan is a plan
					qualified under Internal Revenue Code &sect;401(a) or (b) or
					&sect;457(b)
				</p>
				<p>
					I understand that Principal will rely upon the information
					provided in this form and/or from the current financial
					institution to provide direction regarding the breakdown
					between pre-tax and after-tax (including any Roth
					contributions).
				</p>
				<hr className="dotted util-margin-top-20" />
			</Content>
			<div className="row">
				<div className="col-md-7 col-md-offset-2">
					<div className="text-center util-margin-bottom-30">
						<Tooltip>
							<button
								type="button"
								className="btn btn-link btn-block util-margin-bottom-20"
							>
								Show more
							</button>
						</Tooltip>
						<div>
							<Link
								to="/rollovers/how-about-this"
								className="btn btn-primary btn-lg"
							>
								I'm all in — let's do this!
							</Link>
						</div>
						<Tooltip position="bottom">
							<button
								type="button"
								className="btn btn-link btn-block util-margin-top-20"
							>
								Nope. I need to do some more research.
							</button>
						</Tooltip>
					</div>
				</div>
				<div className="col-md-3">
					<button
						type="button"
						className="btn btn-default"
						onClick={() => togglePanel(!showPanel)}
					>
						Chat now&nbsp;
						<i aria-hidden className="fa fa-comments"></i>
					</button>
				</div>
			</div>

			{showPanel && <ChatPanel bindChatPanel={memoizedTogglePanel} />}
		</ThreeColumnLayout>
	);
};

export default OneLastThing;

import React from "react";
import Modal from "components/generic/modal/modal";
import ModalHeader from "components/generic/modal/modal-header";
import ModalBody from "components/generic/modal/modal-body";

const RothContributionModal: React.FC<{ bindToggle: any }> = ({
	bindToggle
}) => (
	<Modal bindModal={bindToggle}>
		<ModalHeader bindModal={bindToggle}>
			<h2 className="modal-title">Roth (after-tax) contribution</h2>
		</ModalHeader>
		<ModalBody>
			<ul>
				<li>
					You're probably most familiar with a traditional pre-tax
					contribution, which you may have also heard referred to as a
					salary deferral or elective deferral contribution. When you
					make a pre-tax contribution, the amount you invest generally
					comes out of your paycheck before your income is taxed. A
					Roth or after-tax contribution, is basically the opposite.
					When you make a Roth contribution, the money will be
					invested after taxes have been deducted making it and
					potential earnings tax free at retirement, as long as you're
					at least 59 &frac12;, and the money has been in your account
					for at least five years.
				</li>
				<li>
					If you'd like to dive deeper, you can watch our Roth vs
					pre-tax contributions webinar replay to learn more about
					these options.
				</li>
			</ul>
		</ModalBody>
	</Modal>
);

export default RothContributionModal;

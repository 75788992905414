import React, { useContext } from "react";
import Tooltip from "components/generic/tooltip/tooltip";
import { withRouter } from "react-router-dom";
import { StateContext } from "context/global-state";

const LeftNavPanel: React.FC<{ history: any }> = ({ history }) => {
	const { state } = useContext(StateContext);
	const isEnglish = state.language === "English";
	const path = window.location.hash.split("/");
	const customPath = (path[1] === "dashboard" || (path[2] === "investment-options" || "quick-option"));
	return (
		<>
			<div className="col-md-2 hidden-sm hidden-xs" id="left-nav">
				<div className="row" id="left-nav">
					<div className="util-margin-bottom-30 util-padding-left-15 col-md-12 col-xs-2">
						<button
							onClick={() => history.goBack()}
							className="btn btn-link util-padding-0"
						>
							<img
								height="35"
								alt="back-arrow"
								src="media/back.svg"
								width="35"
							/>
						</button>

						<p className="text-muted text-nowrap" id="back-label">
							{isEnglish ? (<>
								{customPath ? "Atrás" : "Back"}
							</>) : "Back"}
						</p>
					</div>

					<div className="util-padding-left-15 col-md-12 col-xs-3 hidden-print">
						<Tooltip
							position="bottom"
							classes="btn btn-link util-padding-0"
						>
							<p className="text-left">
								{isEnglish ? (<>
									{customPath ? "Información y notificaciones del plan (en inglés)" : "Plan notices and information"}
								</>) : "Plan notices and information"}
							</p>
						</Tooltip>
					</div>
				</div>
			</div>
		</>
	);
};

export default withRouter(LeftNavPanel);

import React, { ReactNode } from "react";

const ModalHeader: React.FC<{ children: ReactNode; bindModal: Function }> = ({
	children,
	bindModal
}) => (
	<div className="modal-header">
		<button
			type="button"
			className="close"
			aria-label="Close"
			onClick={() => bindModal()}
		>
			<span aria-hidden>&times;</span>
		</button>
		{children}
	</div>
);

export default ModalHeader;

import React, { useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import { StateContext } from "context/global-state";
import ThreeColumnLayout from "components/layout/three-column-layout";
import "scss/contributions/review-contribution.scss";

const ReviewContributionPage: React.FC = () => {
	const { state } = useContext(StateContext);
	const { preTax, total } = state.contributionRates;
	const { month, day } = state.annualIncreaseDate;
	const { annualIncreases } = state;

	return (
		<ThreeColumnLayout>
			<div className="col-md-9">
				<h1 className="text-center text-midnight util-margin-top-0">
					Your contributions are all set up!
				</h1>
				<p>
					Review the information below. And if you have no changes,
					let's move on.
				</p>
				<hr className="dotted util-margin-top-40" />
				<div className="util-padding-horz-xs-15">
					<div>
						{Object.keys(annualIncreases).map(
							(key: string, i: number) => (
								<Fragment key={i}>
									<div className="util-padding-vert-30 util-border-radius-10 blue-top-border bg-light-gray row">
										<div className="col-xs-8 col-sm-5 col-md-6 util-padding-left-30">
											<h3 className="h5">
												{`${annualIncreases[key][2]} Contribution`}
											</h3>
											<p>of your annual salary.</p>
										</div>
										<div className="col-xs-4 col-sm-3 util-padding-top-10">
											<span className="h1" id={`${key === "preTax" ? 'pre-tax' : 'post-tax'}-contribution-percent`}>
												{key === "preTax"
													? preTax
													: total - preTax}
												<sup className="small">%</sup>
											</span>
										</div>
										<div className="col-sm-4 col-md-3 util-padding-top-sm-5 xs-text-center">
											<Link
												to="/contributions/set-custom-contribution-rate"
												className="btn btn-default btn-lg"
											>
												Change
											</Link>
										</div>
									</div>

									<div className="util-padding-vert-30 util-border-radius-10 bg-light-gray util-margin-vert-5 row">
										<div className="col-xs-8 col-sm-5 col-md-6 text-left util-padding-left-30">
											<h3 className="h5">
												{annualIncreases[key][2]}
												&nbsp;Annual Increase
											</h3>
											{annualIncreases[key][1] !== 0 && (
												<p id={`${key === "preTax" ? 'pre-tax' : 'post-tax'}-top-out`}>
													Every year on&nbsp;
													<span>
														{("0" + month).slice(
															-2
														) +
															"/" +
															("0" + day).slice(
																-2
															)}
													</span>
													, topping out at&nbsp;
													{annualIncreases[key][1]}
													<sup className="small">
														%
													</sup>
												</p>
											)}
										</div>
										<div className="col-xs-4 col-sm-3 util-padding-top-10">
											{annualIncreases[key][0] ? (
												<span className="h1" id={`${key === "preTax" ? 'pre-tax' : 'post-tax'}-annual-increase`}>
													{annualIncreases[key][0]}
													<sup className="small">
														%
													</sup>
												</span>
											) : (
													<span className="h3">
														Not Elected
												</span>
												)}
										</div>
										<div className="col-sm-4 col-md-3 util-padding-top-sm-5 xs-text-center">
											<Link
												to="/contributions/annual-increase"
												className="btn btn-default btn-lg"
											>
												Change
											</Link>
										</div>
									</div>
								</Fragment>
							)
						)}
					</div>
				</div>
				<hr className="dotted" />
				<p>
					By clicking Submit I am acknowledging that the amount
					displayed will apply to future earnings until changed by me.
					I understand my employer may reduce my contributions only
					when required to meet certain plan limits. I am agreeing to
					review statements regularly, and report any discrepancy to
					Principal immediately.
				</p>
				<div className="util-margin-vert-30 text-center">
					<Link
						to={state.experienced ? "/dashboard" : "/investments"}
						className="btn btn-primary btn-lg"
					>
						Submit
					</Link>
				</div>
			</div>
		</ThreeColumnLayout>
	);
};

export default ReviewContributionPage;

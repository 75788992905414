import React from "react";
import { Link } from "react-router-dom";
import ThreeColumnLayout from "components/layout/three-column-layout";
import Content from "components/layout/content";
import Tooltip from "components/generic/tooltip/tooltip";
import RollOversRightPanel from "components/rollovers/rollovers-right-panel";

const HowMuchToRoll: React.FC = () => (
	<ThreeColumnLayout>
		<Content>
			<h1 className="text-center util-margin-top-0">
				How much money are you moving?
			</h1>
			<p>First things first for this process ...and away we go!</p>
			<hr className="dotted" />
			<div className="form-group">
				<div className="input-prefix">
					<span className="prefix">$</span>
					<input
						className="form-control"
						type="tel"
						id="amount-moving"
					/>
				</div>
			</div>
			<p>An estimate is just fine.</p>
			<hr className="dotted util-margin-vert-40" />
			<div className="text-center">
				<Link to="/rollovers/check" className="btn btn-primary">
					Let's keep going
				</Link>
			</div>
		</Content>
		<RollOversRightPanel>
			<h2 className="h3">Not sure how much you have?</h2>
			<p className="util-margin-bottom-15">
				Check a recent statement or log into your previous provider's
				website to see your balance. If not, you can keep going and
				enter this information later.
			</p>
			<Tooltip position="bottom">
				<button
					type="button"
					className="btn btn-default util-margin-bottom-30 btn-md"
				>
					More common questions
				</button>
			</Tooltip>
		</RollOversRightPanel>
	</ThreeColumnLayout>
);

export default HowMuchToRoll;

import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

// Links that look like buttonsfor the landing pages
const DefaultAltButtonLink: React.FC<{
	children: ReactNode;
	route: string;
}> = ({ children, route }) => (
	<Link
		to={route}
		className="btn btn-lg btn-default-alt"
		style={{
			border: "none"
		}}
	>
		{children}
	</Link>
);

export default DefaultAltButtonLink;

import React, { useState, useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { StateContext } from "context/global-state";
import ThreeColumnLayout from "components/layout/three-column-layout";
import Content from "components/layout/content";
import RightPanel from "components/layout/right-panel";
import Tooltip from "components/generic/tooltip/tooltip";
import PortfolioSnapshotModal from "components/modals/portfolio-snapshot-modal";
import "scss/investments/quick-option.scss";

const InvestmentsQuickOption: React.FC = () => {
	const { state } = useContext(StateContext);
	const [showModal, toggleModal] = useState<boolean>(false);
	const memoizedToggleModal = useCallback(() => {
		toggleModal(!showModal);
	}, [showModal]);
	const isEspanol = state.language === "Español";

	return (
		<>
			<ThreeColumnLayout>
				<Content>
					<h1 className="text-center util-margin-0">
						{isEspanol ? "Quick option" : "Opción rápida"}
					</h1>
					<hr className="dotted" />
					<div className="panel panel-default text-left util-margin-vert-25">
						<div className="panel-heading util-padding-25">
							<h2 className="text-white">
								Principal LifeTime Strategic Income Separate Account
							</h2>
							<p className="text-white">
								Multiple Sub-Advisors
							</p>
							<div className="text-regular">
								{isEspanol ? "This is a Target Date Portfolio." : "Este es un portafolio con fecha objetivo."}
								<Tooltip position="top" classes="btn btn-link util-padding-0 util-padding-left-5 text-white">
									{isEspanol ? "Learn more" : "Más información"}
								</Tooltip>
							</div>
						</div>
						<div className="panel-body util-padding-vert-20">
							<Link
								to={state.experienced ? "/dashboard" : "/rollovers"}
								className="btn btn-lg btn-default util-margin-20"
							>
								{isEspanol ? "Yep, I want this one" : "Sí. Quiero esta opción."}
							</Link>
							<button
								style={{
									border: "none"
								}}
								className="btn btn-link util-padding-0 util-margin-left-60"
								onClick={() => toggleModal(true)}
							>
								{isEspanol ? "Portfolio Snapshot" : "Un vistazo a tu portafolio (en inglés)"}
							</button>
						</div>
					</div>
					<div className="d-block util-margin-bottom-20">
						<Link
							to="/investments/investment-options"
							className="btn btn-link center-block"
						>
							{isEspanol ? "I'd like to look at all my options" : "Quiero ver todas mis opciones."}
						</Link>
					</div>
				</Content>
				<RightPanel>
					<h3>{isEspanol ? "Why a Quick Option?" : "¿Por qué elegir una opción rápida?"}</h3>
					<p>
						{isEspanol ?
							"This is the investment option that your organization chose. It could be based on things like the normal retirement age and how comfortable you may be with risk."
							:
							"Esta es la opción de inversión que tu organización elige. Se puede basar en cosas como la edad normal de jubilación y tu nivel de comodidad con el riesgo."
						}
					</p>
				</RightPanel>
			</ThreeColumnLayout>
			{showModal && (
				<PortfolioSnapshotModal bindToggle={memoizedToggleModal} />
			)}
		</>
	);
};

export default InvestmentsQuickOption;

import React, { useContext, useState, useCallback } from "react";
import ThreeColumnLayout from "components/layout/three-column-layout";
import Content from "components/layout/content";
import ContributionsRightPanel from "components/contributions/contributions-right-panel";
import { StateContext } from "context/global-state";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import EditSalary from "components/contributions/edit-salary";
import "scss/contributions/set-contribution-rate.scss";

const SetContributionRatePage: React.FC<{ history: any }> = ({ history }) => {
	const { dispatch } = useContext(StateContext);
	const [expandedPanel, toggleExpand] = useState<boolean>(false);
	const memoizedToggleExpand = useCallback(() => {
		toggleExpand(!expandedPanel);
	}, [expandedPanel]);

	const redirectToAllocationPage = (choice: number) => {
		dispatch({
			type: "updateContributionRates",
			payload: {
				total: choice,
				preTax: choice
			}
		});

		dispatch({
			type: "updateAnnualIncreases",
			payload: {
				preTax: choice === 6 ? [1, 10, "Pre-tax"] : [0, 0, "Pre-tax"],
				postTax: [0, 0, "Roth (after-tax)"]
			}
		});

		history.push("/contributions/allocate-contribution-rate");
	};

	return (
		<ThreeColumnLayout>
			{!expandedPanel ? (
				<>
					<Content>
						<h1 className="util-margin-top-0 text-midnight text-center">
							Set your contribution rate
						</h1>
						<p className="util-margin-bottom-15 util-padding-top-5">
							Contributions are a great way to help your
							retirement savings work harder for you over time.
							The amount you choose below will be automatically
							deducted from each paycheck.
						</p>
						<hr className="dotted util-margin-top-40" />
						<div className="row text-center util-margin-horz-0">
							<div className="col-sm-6 util-padding-vert-30 util-margin-horz-xs-60 util-margin-horz-sm-0">
								<button
									className="btn btn-primary btn-lg btn-block-xs"
									onClick={() => redirectToAllocationPage(10)}
								>
									10%
								</button>
							</div>
							<div className="col-sm-6 util-padding-vert-30 util-border-radius-20">
								<div className="row">
									<div className="util-margin-bottom-xs-30 util-margin-bottom-sm-10 util-margin-horz-xs-60 util-margin-horz-sm-0">
										<button
											className="btn btn-primary btn-lg btn-block-xs"
											onClick={() =>
												redirectToAllocationPage(6)
											}
										>
											6%
										</button>
										<p className="small util-padding-10">
											with annual 1% increase up to 10%
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="triangle"></div>
						<div className="bg-light-green util-border-radius-20 util-padding-30">
							Check that you're contributing enough to get the
							full match.
						</div>
						<div className="text-center util-margin-vert-20">
							<Link to="/contributions/set-custom-contribution-rate">
								I want to set a different rate
							</Link>
						</div>
					</Content>

					<ContributionsRightPanel
						bindToggle={memoizedToggleExpand}
					/>
				</>
			) : (
				<EditSalary bindToggle={memoizedToggleExpand} />
			)}
		</ThreeColumnLayout>
	);
};

export default withRouter(SetContributionRatePage);

import React, { ReactNode, useContext } from "react";
import RightPanel from "components/layout/right-panel";
import Card from "components/layout/card";
import { StateContext } from "context/global-state";
import NumberFormat from "react-number-format";
import "scss/contributions/set-contribution-rate.scss";

const ContributionsRightPanel: React.FC<{
	children?: ReactNode;
	bindToggle: Function;
}> = ({ children, bindToggle }) => {
	const { state } = useContext(StateContext);

	return (
		<RightPanel>
			<h2 className="h3">Here's a quick overview</h2>
			<p className="util-margin-top-30">Annual salary</p>
			<p className="h3 util-margin-bottom-15 util-margin-top-0 text-bold">
				<NumberFormat
					value={state.income.salary}
					displayType="text"
					thousandSeparator={true}
					prefix="$"
					fixedDecimalScale={true}
					decimalScale={2}
				/>
			</p>
			<button
				onClick={() => bindToggle()}
				className="btn-link util-padding-0 text-bold"
			>
				Edit
			</button>
			{children}
			<hr />
			<h2 className="h3 util-margin-top-30">Read and review</h2>
			<ul className="list-unstyled">
				<Card
					title="Get psyched to save: Motivation for your retirement dreams"
					thumbnail="media/card_1.jpg"
					alt="Retirement Dreams"
				/>
				<Card
					title="6 habits of successful savers"
					thumbnail="media/card_2.jpg"
					alt="Six Successful Habits"
				/>
			</ul>

			<h2 className="h3 util-margin-top-30">Watch to feel wiser</h2>

			<ul className="list-unstyled">
				<Card
					title="Busting through 4 retirement savings myths"
					thumbnail="media/card_3.jpg"
					alt="Retirement Savings Webinar"
				/>

				<Card
					title="Roth vs pre-tax contributions webinar"
					thumbnail="media/card_4.jpg"
					alt="Roth vs Pre-Tax"
				/>
			</ul>

			<p className="h3 util-margin-bottom-15 util-margin-top-0 text-bold"></p>
		</RightPanel>
	);
};

export default ContributionsRightPanel;

import React, { useContext, useState, Fragment, useCallback } from "react";
import { Link } from "react-router-dom";
import { StateContext } from "context/global-state";
import ThreeColumnLayout from "components/layout/three-column-layout";
import Content from "components/layout/content";
import ContributionsRightPanel from "components/contributions/contributions-right-panel";
import Tooltip from "components/generic/tooltip/tooltip";
import ContributionPerPayCheck from "components/layout/contribution-per-paycheck";
import EditSalary from "components/contributions/edit-salary";
import RothContributionModal from "components/modals/roth-contribution-modal";
import PreTaxContributionModal from "components/modals/pre-tax-contribution-modal";
import IncrementButton from "components/generic/buttons/increment-button";

const AllocateContributionPage: React.FC = () => {
	const { state, dispatch } = useContext(StateContext);
	const { total, preTax } = state.contributionRates;
	const [showModal1, toggleModal1] = useState<boolean>(false);
	const [showModal2, toggleModal2] = useState<boolean>(false);
	const [expandedPanel, toggleExpand] = useState<boolean>(false);
	const memoizedToggleExpand = useCallback(() => {
		toggleExpand(!expandedPanel);
	}, [expandedPanel]);
	const memoizedToggleModal1 = useCallback(() => {
		toggleModal1(!showModal1);
	}, [showModal1]);
	const memoizedToggleModal2 = useCallback(() => {
		toggleModal2(!showModal2);
	}, [showModal2]);
	const updatePreTaxRate = useCallback(
		(increment: number) => {
			if (preTax + increment > total || preTax + increment < 0) return;

			dispatch({
				type: "updateContributionRates",
				payload: {
					...state.contributionRates,
					preTax: preTax + increment
				}
			});
		},
		[preTax, total, state.contributionRates, dispatch]
	);

	return (
		<ThreeColumnLayout>
			{!expandedPanel ? (
				<>
					<Content>
						<h1 className="text-midnight util-margin-top-0 text-center">
							Set your Pre-tax and Roth (after-tax) contributions
						</h1>
						<p>
							Your retirement account allows you to make both
							Pre-tax and Roth (after-tax) contributions, which
							means you have some options when it comes to saving
							for retirement.
						</p>
						<hr className="dotted" />
						<div className="util-margin-bottom-30">
							{Object.keys(state.annualIncreases).map(
								(key: string, i: number) => (
									<Fragment key={i}>
										<div className="row">
											<div className="util-margin-top-10 col-md-5 col-lg-6 col-xl-7">
												<h3 className="h5">
													{`${state.annualIncreases[key][2]} contribution amount`}
												</h3>
												<p>
													{key === "preTax"
														? "This contribution is made before taxes are deducted from your paycheck."
														: "This contribution is made after taxes have been taken from your paycheck, so you're essentially paying taxes now so that you can withdraw your money tax-free in retirement, if certain requirements are met."}
												</p>
												<button
													className="btn btn-link util-padding-0"
													onClick={() => {
														key === "preTax"
															? toggleModal1(true)
															: toggleModal2(
																true
															);
													}}
												>
													<small>
														Are you familiar with
														these options?
													</small>
												</button>
											</div>
											<div className="util-margin-top-60 col-md-7 col-lg-6 col-xl-5">
												<div>
													<div className="xs-text-center md-text-right">
														<IncrementButton
															increment={false}
															id={`${state.annualIncreases[key][2] === 'Pre-tax' ? 'pretax' : 'roth'}-decrement-button`}
															handleIncrement={() =>
																updatePreTaxRate(
																	key ===
																		"preTax"
																		? -1
																		: 1
																)
															}
														>
															<i id="pretax-decrement" className="fa fa-minus"></i>
														</IncrementButton>
														<span id={`${state.annualIncreases[key][2] === 'Pre-tax' ? 'pretax' : 'roth'}-percent`} className="h1 text-midnight util-padding-horz-20">
															{key === "preTax"
																? preTax
																: total -
																preTax}
															<sup className="small">
																%
															</sup>
														</span>
														<IncrementButton
															increment={true}
															id={`${state.annualIncreases[key][2] === 'Pre-tax' ? 'pretax' : 'roth'}-increment-button`}
															handleIncrement={() =>
																updatePreTaxRate(
																	key ===
																		"preTax"
																		? 1
																		: -1
																)
															}
														>
															<i className="fa fa-plus"></i>
														</IncrementButton>
													</div>
												</div>
												<div className="xs-text-center md-text-right">
													<Tooltip
														classes="btn btn-link util-padding-0"
														position="bottom"
													>
														<small>
															Contribute by dollar
															amt.
														</small>
													</Tooltip>
												</div>
											</div>
										</div>
										<hr />
									</Fragment>
								)
							)}
							<div className="row">
								<div className="util-margin-top-10 col-sm-12 col-md-6">
									<h3 className="h5">
										Total contribution rate
									</h3>
								</div>
								<div className="util-margin-top-10 col-sm-12 col-md-6 xs-text-center md-text-right">
									<span className="h2" id="total-contribution-rate">
										{total}
										<sup className="small">%</sup>
									</span>
								</div>
							</div>
						</div>
						<div className="bg-light-green util-border-radius-20 util-margin-top-30 util-padding-30">
							When you make a pre-tax contribution, the amount you
							invest generally comes out of your paycheck before
							your income is taxed. A Roth or after-tax
							contribution, is basically the opposite.
						</div>
						<hr className="dotted" />
						<div className="text-center">
							<Link
								to="/contributions/annual-increase"
								className="btn btn-primary btn-lg util-margin-bottom-30"
							>
								Next
							</Link>
						</div>
					</Content>
					<ContributionsRightPanel bindToggle={memoizedToggleExpand}>
						<p className="util-margin-top-30">Contribution rate</p>
						<p className="h2">
							{total}
							<sup className="small">%</sup>
						</p>
						<Link
							to="/contributions/set-custom-contribution-rate"
							className="btn btn-link util-padding-0"
						>
							Edit
						</Link>
						<ContributionPerPayCheck />
					</ContributionsRightPanel>
				</>
			) : (
					<EditSalary bindToggle={memoizedToggleExpand} />
				)}
			{showModal1 && (
				<PreTaxContributionModal bindToggle={memoizedToggleModal1} />
			)}

			{showModal2 && (
				<RothContributionModal bindToggle={memoizedToggleModal2} />
			)}
		</ThreeColumnLayout>
	);
};

export default AllocateContributionPage;

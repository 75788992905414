import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Tooltip from "components/generic/tooltip/tooltip";
import { StateContext } from "context/global-state";
import "scss/login.scss";

const LoginPage: React.FC<{ history: any }> = ({ history }) => {
	const { dispatch } = useContext(StateContext);

	const assignExperience = (transitioning: boolean) => {
		dispatch({
			type: "updateExperience",
			payload: transitioning,
		});
		history.push("/create-your-account");
	};

	return (
		<>
			<div className="video-container">
				<img
					src="media/onboardingBackground.jpg"
					alt="onboarding background"
					width="150px"
					className="image-background"
				/>
			</div>
			<div className="overlay"></div>
			<div className="container">
				<div className="row row-flex">
					<div className="col-sm-6 col-xs-12 col-flex onboarding-landing-container">
						<div className="row util-margin-top-60">
							<Link to="/">
								<img
									src="media/logo-onecolor-right.svg"
									alt="principal p logo"
									width="150px"
									className="principal-logo"
								/>
							</Link>
						</div>
						<div className="flex-bottom">
							<h1 className="hidden-xs">
								Welcome, we're so glad you're here. In just a
								few steps, you'll be on your way to planning for
								retirement.
							</h1>
							<h1 className="visible-xs hidden-sm h2">
								Welcome, we're so glad you're here. In just a
								few steps, you'll be on your way to planning for
								retirement.
							</h1>
							<div className="row util-margin-vert-30">
								<div className="col-xs-12 button-group flex-horizontal">
									<div className="flex-vertical">
										<button
											id="get-started-transition"
											className="btn btn-lg btn-primary util-margin-right-25"
											onClick={() =>
												assignExperience(true)
											}
										>
											Get started
											<small style={{ display: "block" }}>
												&#40;Transition Experience&#41;
											</small>
										</button>

										<button
											id="get-started-newly-eligible"
											className="btn btn-lg btn-primary util-margin-right-25"
											onClick={() =>
												assignExperience(false)
											}
										>
											Get started
											<small style={{ display: "block" }}>
												&#40;Newly eligible&#41;
											</small>
										</button>
									</div>
									<Tooltip position="right">
										<button
											className="btn btn-lg btn-default"
											id="login-button"
										>
											Login
										</button>
									</Tooltip>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default LoginPage;

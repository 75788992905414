import React, { useState, SyntheticEvent, useEffect } from "react";

const AnimatedButtons: React.FC<{
	buttonTextTrue: string;
	buttonTextFalse: string;
	buttonTextClickTrue: Function;
	buttonTextClickFalse: Function;
}> = ({
	buttonTextTrue,
	buttonTextFalse,
	buttonTextClickTrue,
	buttonTextClickFalse
}) => {
	// did user select anything yet
	const [userSelected, updateUserSelected] = useState<boolean>(false);
	// if user did select, was it yes or no
	const [selectionMade, updateSelectionMade] = useState<boolean>(false);

	const handleButtonClick = (e: SyntheticEvent) => {
		let element = e.target as HTMLButtonElement;
		updateUserSelected(true);
		updateSelectionMade(element.value === "true" ? true : false);
	};

	useEffect(() => {
		userSelected &&
			(selectionMade ? buttonTextClickTrue() : buttonTextClickFalse());
	}, [
		buttonTextClickFalse,
		buttonTextClickTrue,
		selectionMade,
		userSelected
	]);

	return (
		<>
			{!userSelected ? (
				<div className="conversation-answer animated fadeInUpSmall animation-delay-first">
					<button
						className="btn btn-default util-margin-right-15"
						type="button"
						onClick={e => handleButtonClick(e)}
						value="true"
					>
						{buttonTextTrue}
					</button>
					<button
						className="btn btn-default"
						type="button"
						onClick={e => handleButtonClick(e)}
						value="false"
					>
						{buttonTextFalse}
					</button>
				</div>
			) : (
				<div className="conversation-content conversation-right conversation-right-top animation-delay-50 animated fadeInUpSmall">
					{selectionMade ? buttonTextTrue : buttonTextFalse}
				</div>
			)}
		</>
	);
};

export default AnimatedButtons;

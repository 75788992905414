import React, { useState, useCallback, useContext, useRef, useEffect } from "react";
import { StateContext } from "context/global-state";
import ThreeColumnLayout from "components/layout/three-column-layout";
import Content from "components/layout/content";
import RightPanel from "components/layout/right-panel";
import Tooltip from "components/generic/tooltip/tooltip";
import InvestmentOptionsWalkthrough from "components/investments/Investment-options-walkthrough";
import "scss/investments/investment-options.scss";
import NumberFormat from "react-number-format";
import TmrModal from "components/modals/tmr-modal";

const InvestmentTMR: React.FC = () => {
	const { state } = useContext(StateContext);
	const [salary, updateSalary] = useState(0);
	const [showModal, toggleModal] = useState(false);
	const isEspanol = state.language === "Español";
	const [expandedPanel, toggleExpand] = useState<boolean>(false);
	const inputWrapper = useRef<HTMLDivElement>(null);
	const [retirementAge, updateRetirementAge] = useState<number>();
	const memoizedUpdateSalary = useCallback((input) => {
		updateSalary(parseFloat(input.replace(/[$,]/g, "")));
	}, []);
	const memoizedToggleModal = useCallback(() => {
		toggleModal(!showModal);
	}, [showModal]);

	useEffect(() => {
		inputWrapper.current && inputWrapper.current.children[0].tagName === "INPUT" && inputWrapper.current.children[0].classList.add("form-control");
	}, []);

	return (
		<ThreeColumnLayout>
			{!expandedPanel ? (
				<>
					<Content>
						<h1 className="text-center util-margin-top-0">
							{isEspanol ? (
								<>
									Target My Retirement<sup>&reg;</sup> Managed Account
								</>
							) : (
								<>
									Dirigirse a mi cuenta administrada <sup> &reg; </sup> de jubilación
								</>
							)}
						</h1>
						<p>
							{isEspanol
								? "We'll need to know a little bit about you, starting with your salary."
								: "Necesitaremos saber un poco sobre usted, comenzando por su salario."}
						</p>
						<form name="tmr">
							<div className="form-group">
								<label>Annual Salary</label>
								<div className="input-prefix annual-salary" ref={inputWrapper}>
									<NumberFormat
										className="form-control"
										value={salary}
										displayType="input"
										thousandSeparator={true}
										prefix="$"
										fixedDecimalScale={true}
										decimalScale={2}
										allowNegative={false}
										placeholder="Enter Annual Salary"
										onChange={(e: any) => memoizedUpdateSalary(e.target.value)}
									/>
								</div>
								<Tooltip>
									<button className="btn-link">
										<strong>{isEspanol ? "Paid Hourly? Try our annual pay calculator." : "¿Pagado por hora? Pruebe nuestra calculadora de pago anual."}</strong>
									</button>
								</Tooltip>
							</div>
							<div className="form-group">
								<label>Desired retirement age</label>
								<p>When's the big year? Just enter the age you want to retire, and we'll do that math.</p>
								<div className="input-prefix annual-salary">
									<input
										type="number"
										min="0"
										placeholder="Enter retirement age"
										className="form-control"
										onChange={(e: any) => updateRetirementAge(Number(e.target.value))}
										value={retirementAge}
									/>
								</div>
							</div>
						</form>
						<div className="col-md-12 bg-light-gray util-margin-bottom-md-30 util-padding-30" style={{ borderRadius: "15px" }}>
							<div className="col-md-2">
								<img alt="" className="util-margin-top-20" src="media/info.png" />
							</div>
							<div className="col-md-10">
								<p>
									<b>
										After your retirement account is all set up, you can log back in to principal.com and make the Morningstar Investment Management LLC
										recommendations even more personalized by adding more information about you.
									</b>
								</p>
								<p>
									For example, Morningstar Investment Management takes into account the gender that's reported by your employer. On average, women outlive men.
									And since the goal is to make sure you don't run out of money, we need to know how long you may live. Gender is one of many ways to ball park
									your lifespan. If no gender is reported by your organization, you'll be defaulted into the more conservative approach of the female life
									expectancy.
								</p>
							</div>
						</div>
						<div className="text-center">
							<button className="btn btn-primary util-margin-bottom-20" onClick={() => toggleModal(true)}>
								Next
							</button>
						</div>
					</Content>
					<RightPanel>
						{isEspanol ? (
							<>
								<h3 className="util-margin-top-30">Get the details</h3>

								<Tooltip classes="btn btn-link text-bold" position="bottom">
									Participation Agreement (PDF)
								</Tooltip>
								<Tooltip classes="btn btn-link text-bold" position="bottom">
									Fees for the Principal Managed Accounts Program
								</Tooltip>
								<Tooltip classes="btn btn-link text-bold" position="bottom">
									Disclosure Brochure for Morningstar Investment Management (PDF)
								</Tooltip>
							</>
						) : (
							<>
								<p className="h5">Haz esta prueba para saber qué tipo de inversionista eres.</p>
								<div className="text-center">
									<Tooltip classes="btn btn-link">Evaluación</Tooltip>
								</div>
								<hr />
								<h3>Construye tu futuro</h3>
								<img src="media/coffee.jpeg" alt="Coffee" width="100%" />
								<div>
									<Tooltip classes="btn-link" position="bottom">
										Es hora de decirle “hola” al futuro de tus finanzas
									</Tooltip>
								</div>
							</>
						)}
					</RightPanel>
				</>
			) : (
				<InvestmentOptionsWalkthrough closeWindow={() => toggleExpand(false)} />
			)}
			{showModal && <TmrModal bindToggle={memoizedToggleModal} />}
		</ThreeColumnLayout>
	);
};

export default InvestmentTMR;

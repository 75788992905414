import React from "react";
import { Link } from "react-router-dom";
import ThreeColumnLayout from "components/layout/three-column-layout";
import Content from "components/layout/content";
import RollOversRightPanel from "components/rollovers/rollovers-right-panel";

const GetYourCheck: React.FC = () => (
	<ThreeColumnLayout>
		<Content>
			<h1 className="text-center util-margin-top-0">
				Get your rollover check
			</h1>
			<p>
				Each plan is different. You can take the reins now, but we've
				got your back and can help if you need it. Take a look at the
				instructions to see what you might expect.
			</p>
			<hr className="dotted util-margin-bottom-sm-30" />
			<div className="row util-margin-right-0">
				<div className="col-md-4">
					<h4 className="text-left util-margin-top-20">
						What to expect
					</h4>
				</div>
				<div className="col-md-8 callout">
					<ul>
						<li>
							Start by getting a hold of your current provider to
							get your money moving - usually by calling.
						</li>
						<li>
							They'll typically mail you a check to forward to us
							(details for your provider below).
						</li>
						<li>
							If there are forms to fill out, they should tell you
							where to send them and if it's a fax, email, or
							mail.
						</li>
					</ul>
				</div>
			</div>
			<div className="row util-margin-right-0">
				<div className="col-md-4">
					<h4 className="text-left util-margin-top-15">
						Check information
					</h4>
				</div>

				<div className="col-md-8 callout">
					<ul>
						<li className="util-margin-bottom-15">
							Make check payable to: Principal Life Insurance
							Company <br />
						</li>
						<li className="util-margin-bottom-15">
							Send check to: <br /> FBO Name: John Customer <br />{" "}
							Principal Financial Group
							<br /> P.O. Box 9999 <br /> Des Moines, IA
							50306-9394 <br />
						</li>
						<li>
							Account or Plan ID: <br /> 9-99999
						</li>
					</ul>
				</div>
			</div>
			<hr className="dotted util-margin-vert-30" />
			<div className="text-center util-margin-bottom-20">
				<Link to="/rollovers/confirmation" className="btn btn-primary">
					Next
				</Link>
			</div>
		</Content>
		<RollOversRightPanel classes="util-margin-bottom-30">
			<h2 className="h3 util-margin-bottom-15 text-bold">Questions?</h2>
		</RollOversRightPanel>
	</ThreeColumnLayout>
);

export default GetYourCheck;

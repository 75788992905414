import React, { useCallback, useState, ReactNode } from "react";
import RightPanel from "components/layout/right-panel";
import ChatPanel from "components/layout/chat-panel";

const RollOversRightPanel: React.FC<{
	children: ReactNode;
	classes?: string;
}> = ({ children, classes }) => {
	const [showPanel, togglePanel] = useState<boolean>(false);
	const memoizedTogglePanel = useCallback(() => {
		togglePanel(!showPanel);
	}, [showPanel]);

	return (
		<>
			<RightPanel>
				{children}
				<div>
					<button
						type="button"
						className={`btn btn-default ${classes}`}
						onClick={() => togglePanel(!showPanel)}
					>
						Chat now&nbsp;
						<i aria-hidden className="fa fa-comments"></i>
					</button>
				</div>
			</RightPanel>

			{showPanel && <ChatPanel bindChatPanel={memoizedTogglePanel} />}
		</>
	);
};

export default RollOversRightPanel;

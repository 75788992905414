import React from "react";
import { Link } from "react-router-dom";
import ThreeColumnLayout from "components/layout/three-column-layout";
import Content from "components/layout/content";
import RollOversRightPanel from "components/rollovers/rollovers-right-panel";

const EnterYourPhone: React.FC = () => {
	return (
		<ThreeColumnLayout>
			<Content>
				<h1 className="text-center util-margin-top-0">
					Give us your number
				</h1>
				<p>
					Just in case we have questions about the information you
					added.
				</p>
				<hr className="dotted" />
				<div className="form-group">
					<div className="input-prefix">
						<input
							className="form-control"
							type="tel"
							id="phone-number"
							placeholder="(999) 999-9999"
						/>
					</div>
				</div>
				<hr className="dotted util-margin-vert-40" />
				<div className="text-center">
					<Link
						to="/rollovers/one-last-thing"
						className="btn btn-primary"
					>
						Next
					</Link>
				</div>
			</Content>
			<RollOversRightPanel classes="util-margin-bottom-30">
				<h2 className="h3 text-bold">Why are we asking this?</h2>
				<p className="util-margin-bottom-15">
					It may help us move a little faster if we can call you right
					away with questions.
				</p>
			</RollOversRightPanel>
		</ThreeColumnLayout>
	);
};

export default EnterYourPhone;

import React, { ReactNode } from "react";
import "scss/_common-styles.scss";

const RightPanel: React.FC<{ children: ReactNode; minHeight?: number }> = ({
	children,
	minHeight
}) => (
	<div
		style={{
			minHeight
		}}
		className="col-md-3 bg-light-gray util-margin-bottom-md-30 util-padding-20"
		id="sidebar"
	>
		<img alt="" className="util-margin-top-20" src="media/info.png" />
		<div className="sidebar-content bg-light-gray">
			<div>{children}</div>
		</div>
	</div>
);

export default RightPanel;

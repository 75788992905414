import React, { useContext, useState, useCallback } from "react";
import { StateContext } from "context/global-state";
import ThreeColumnLayout from "components/layout/three-column-layout";
import Content from "components/layout/content";
import ContributionsRightPanel from "components/contributions/contributions-right-panel";
import Tooltip from "components/generic/tooltip/tooltip";
import ContributionPerPayCheck from "components/layout/contribution-per-paycheck";
import { daysInMonth, months } from "utility/dates";
import { Link } from "react-router-dom";
import EditSalary from "components/contributions/edit-salary";
import DisclaimerModal from "components/modals/disclaimer-modal";
import IncrementButton from "components/generic/buttons/increment-button";

const AnnualIncreasePage: React.FC = () => {
	const { state, dispatch } = useContext(StateContext);
	const { annualIncreases } = state;
	const [showModal, toggleModal] = useState<boolean>(false);
	const [expandedPanel, toggleExpand] = useState<boolean>(false);
	const memoizedToggleExpand = useCallback(() => {
		toggleExpand(!expandedPanel);
	}, [expandedPanel]);
	const memoizedToggleModal = useCallback(() => {
		toggleModal(!showModal);
	}, [showModal]);
	// Function updates states for annual rate increase and maximum rate for contributions. Third argument determines whether the annual rate increase or the maxium rate gets updated.
	const updateAnnualIncrease = useCallback(
		(taxType: string, newValue: number, updateMax: boolean) => {
			if (newValue <= 100 && newValue >= 0) {
				dispatch({
					type: "updateAnnualIncreases",
					payload: {
						...annualIncreases,
						[taxType]: [
							!updateMax ? newValue : annualIncreases[taxType][0],
							updateMax ? newValue : annualIncreases[taxType][1],
							annualIncreases[taxType][2]
						]
					}
				});
			}
		},
		[annualIncreases, dispatch]
	);

	return (
		<ThreeColumnLayout>
			{!expandedPanel ? (
				<>
					<Content>
						<h1 className="text-midnight util-margin-top-0 text-center">
							Set an annual increase
						</h1>
						<p>
							An annual increase helps you ease your way into
							building a savings plan and progress toward the
							retirement you want.
						</p>
						<div>
							{[
								["preTax", "Pre-tax"],
								["postTax", "Roth (after-tax)"]
							].map(tax => (
								<div key={tax[1]}>
									<h3 className="h5 util-margin-top-30 text-left text-midnight">
										{tax[1]} annual increase
									</h3>
									<hr className="dotted util-margin-top-5" />
									<div className="row">
										<div className="util-margin-top-10 col-md-5 col-lg-6 col-xl-7">
											<h3 className="h5">
												Increase my contribution by
											</h3>
											<p>
												Every year, your contribution
												will increase by this amount.
											</p>
										</div>
										<div className="util-margin-top-25 col-md-7 col-lg-6 col-xl-5">
											<div className="xs-text-center md-text-right">
												<IncrementButton
													increment={false}
													id={`${tax[0]}-increase-by-decrement-button`}
													handleIncrement={() =>
														updateAnnualIncrease(
															tax[0],
															annualIncreases[
															tax[0]
															][0] - 1,
															false
														)
													}
												>
													<i className="fa fa-minus"></i>
												</IncrementButton>
												<span className="h1 text-midnight util-padding-horz-20">
													{annualIncreases[tax[0]][0]}
													<sup className="small">
														%
													</sup>
												</span>
												<IncrementButton
													increment={true}
													id={`${tax[0]}-increase-by-increment-button`}
													handleIncrement={() =>
														updateAnnualIncrease(
															tax[0],
															annualIncreases[
															tax[0]
															][0] + 1,
															false
														)
													}
												>
													<i className="fa fa-plus"></i>
												</IncrementButton>
											</div>
										</div>
									</div>
									<hr />
									<div className="row util-margin-bottom-30">
										<div className="util-margin-top-10 col-md-5 col-lg-6 col-xl-7">
											<h3 className="h5">
												Until my contribution rate is
											</h3>
											<p>
												Your contribution will stay
												steady once it reaches this
												amount. Studies say you may need
												to save 10% plus other
												contributions.&nbsp;
												<button className="btn btn-link util-padding-top-0 util-padding-right-0 util-padding-bottom-0 util-padding-left-0">
													<i
														className="fa fa-info-circle"
														onClick={() =>
															toggleModal(true)
														}
													></i>
												</button>
											</p>
										</div>
										<div className="util-margin-top-25 col-md-7 col-lg-6 col-xl-5">
											<div className="xs-text-center md-text-right">
												<IncrementButton
													increment={false}
													id={`${tax[0]}-until-decrement-button`}
													handleIncrement={() =>
														updateAnnualIncrease(
															tax[0],
															annualIncreases[
															tax[0]
															][1] - 1,
															true
														)
													}
												>
													<i className="fa fa-minus"></i>
												</IncrementButton>
												<span className="h1 text-midnight util-padding-horz-20">
													{annualIncreases[tax[0]][1]}
													<sup className="small">
														%
													</sup>
												</span>
												<IncrementButton
													increment={true} id={`${tax[0]}-until-increment-button`}

													handleIncrement={() =>
														updateAnnualIncrease(
															tax[0],
															annualIncreases[
															tax[0]
															][1] + 1,
															true
														)
													}
												>
													<i className="fa fa-plus"></i>
												</IncrementButton>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
						<h3 className="util-margin-top-30 text-midnight h5">
							Effective date
						</h3>
						<hr className="dotted util-margin-top-5" />
						<div className="row">
							<div className="util-margin-top-10 col-sm-12 col-md-6 col-lg-5">
								<h3 className="h5">Effective on</h3>
								<p>Increase occurs every year on this date</p>
							</div>
							<div className="util-margin-top-25 col-sm-12 col-md-6 col-lg-7">
								<div className="row">
									<div className="form-group col-xs-8 col-sm-9 col-md-12 col-lg-7">
										<select
											className="form-control"
											onChange={e => {
												return dispatch({
													type:
														"updateAnnualIncreaseDate",
													payload: {
														month: e.target.value,
														day:
															state
																.annualIncreaseDate
																.day
													}
												});
											}}
											value={
												state.annualIncreaseDate.month
											}
										>
											{months.map(
												(month: string, i: number) => (
													<option
														key={`month ${i}`}
														value={`${i + 1}`}
													>
														{month}
													</option>
												)
											)}
										</select>
									</div>
									<div className="form-group col-xs-4 col-sm-3 col-md-12 col-lg-5">
										<select
											className="form-control"
											onChange={e =>
												dispatch({
													type:
														"updateAnnualIncreaseDate",
													payload: {
														month:
															state
																.annualIncreaseDate
																.month,
														day: e.target.value
													}
												})
											}
											value={state.annualIncreaseDate.day}
										>
											{daysInMonth(
												state.annualIncreaseDate.month
											).map((day: number) => (
												<option
													value={day}
													key={`day ${day}`}
												>
													{day}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>
						</div>
						<hr />
						<div className="bg-light-green util-border-radius-20 util-margin-top-30 util-padding-30">
							Every percent counts &mdash; your savings rate can
							make a big difference over time, making annual
							increase important to help with your goals.
						</div>
						<hr className="dotted" />
						<div className="text-center">
							<Link
								to="/contributions/review"
								className="btn btn-primary btn-lg util-margin-30"
							>
								Love it - what's next?
							</Link>
							<br />
							<Tooltip position="bottom">
								<button
									className="btn btn-link util-margin-20"
									type="button"
								>
									Skip this step
								</button>
							</Tooltip>
						</div>
						<div
							className="util-margin-vert-30"
							key="disclosures"
						></div>
					</Content>
					<ContributionsRightPanel bindToggle={memoizedToggleExpand}>
						<ContributionPerPayCheck />
						<p className="util-margin-top-30">
							Pre-tax Contribution
						</p>
						<p className="h2">
							{state.contributionRates.preTax}
							<sup className="small">%</sup>
						</p>
						<p className="util-margin-top-30">
							Roth (after-tax) Contribution
						</p>
						<p className="h2">
							{state.contributionRates.total -
								state.contributionRates.preTax}
							<sup className="small">%</sup>
						</p>
					</ContributionsRightPanel>
				</>
			) : (
					<EditSalary bindToggle={memoizedToggleExpand} />
				)}
			{showModal && <DisclaimerModal bindToggle={memoizedToggleModal} />}
		</ThreeColumnLayout>
	);
};

export default AnnualIncreasePage;

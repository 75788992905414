import React, { useContext } from "react";
import DefaultAltButtonLink from "components/generic/buttons/default-alt-button-link";
import Landing from "components/layout/landing";
import { Link } from "react-router-dom";
import { StateContext } from "context/global-state";

const RolloversFirstStep: React.FC = () => {
	const { state } = useContext(StateContext);
	const isEspanol = state.language === "Español";
	return (
		<>
			<Landing>
				<img src="media/rollovers-icon.png" alt="large-icon" />
				<h1>
					{isEspanol ?
						"See retirement savings in one place."
						:
						"Todos tus ahorros para la jubilación en un solo lugar."
					}
				</h1>
				<p>
					{isEspanol ?
						"We'll help you roll eligible outside retirement savings into your retirement account."
						:
						"Te ayudamos a pasar tus ahorros de retiro externos a tu cuenta de jubilación. Esto se conoce en inglés como 'rollover'."
					}
				</p>
				<div className="util-margin-vert-40">
					<DefaultAltButtonLink route="/rollovers/how-much-to-roll">
						{isEspanol ? "Let's get started" : "Comencemos"}
					</DefaultAltButtonLink>
				</div>
				<div>
					<Link to="/dashboard" className="btn btn-link center-block text-white">
						{isEspanol ?
							"I have the funds to roll in but I don't want to do it right now."
							:
							"Tengo fondos que se pueden transferir pero no deseo hacerlo ahora."
						}
					</Link>
				</div>
				<Link to="/dashboard" className="btn btn-link center-block text-white">
					{isEspanol ?
						"I don't have funds to roll in."
						:
						"No tengo fondos para transferir."
					}
				</Link>
			</Landing>
		</>
	);
};

export default RolloversFirstStep;

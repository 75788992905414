import React, { useContext } from "react";
import { StateContext } from "context/global-state";
import Modal from "components/generic/modal/modal";
import ModalHeader from "components/generic/modal/modal-header";
import ModalBody from "components/generic/modal/modal-body";
import ModalFooter from 'components/generic/modal/modal-footer';
import { Link } from 'react-router-dom';

const SkipThisStep: React.FC<{ bindToggle: any }> = ({ bindToggle }) => {
	const { state } = useContext(StateContext);
	const isEspanol = state.language === "Español";

	return (
		<Modal bindModal={bindToggle}>
			<ModalHeader bindModal={bindToggle}>
				<h2 className="modal-title">{isEspanol ? "Skipping investments section" : "Omitir la sección de inversiones"}</h2>
			</ModalHeader>
			<ModalBody>
				<p>
					{isEspanol ?
						"You can skip this section now and come back to make a choice later. For now (and until you come back to make changes), your contributions will be put into Principal LifeTime Strategic Income Separate Account because it's the plan's quick option."
						:
						"Puedes omitir esta sección en este momento y regresar después para hacer una elección. Por ahora (y hasta que regreses para hacer cambios), tus contribuciones se dirigirán a Principal LifeTime Strategic Income Separate Account, que es la opción rápida del plan."
					}
				</p>
			</ModalBody>
			<ModalFooter bindModal={bindToggle}>
				<Link to="/rollovers" className="btn btn-primary">{isEspanol ? "Continue" : "Continuar"}</Link>
			</ModalFooter>
		</Modal>
	)
};

export default SkipThisStep;

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import DefaultAltButtonLink from "components/generic/buttons/default-alt-button-link";
import Landing from "components/layout/landing";
import Tooltip from "components/generic/tooltip/tooltip";
import { StateContext } from "context/global-state";

const FirstStepPage: React.FC = () => {
	const { state } = useContext(StateContext);
	const isEspanol = state.language === "Español";
	return (
		<>
			<Landing>
				<img src="media/contributions-icon.png" alt="large-icon" />
				<h1>
					{isEspanol ?
						"First step, set up your contributions so your savings work hard for you."
						:
						"Primero: configura una cuenta de jubilación para que tus ahorros trabajen por ti."
					}
				</h1>
				{isEspanol ?
					<p>
						Be sure to read the
						<Tooltip
							classes="text-white text-bold"
							position="left"
						>
							&nbsp;notices&nbsp;
						</Tooltip>
						to help understand the plan details.
					</p>
					:
					<p>
						Lee las
						<Tooltip
								classes="text-white text-bold"
								position="left"
							>
								&nbsp;notificaciones&nbsp;
						</Tooltip>
						para entender los detalles.
					</p>
				}
				<div className="util-margin-vert-40">
					<DefaultAltButtonLink route="/contributions/set-contribution-rate">
						{isEspanol ? "Set your contribution rate" : "Configurar mi tasa de contribución"}
					</DefaultAltButtonLink>
				</div>
				<Link to="/investments" className="text-white">
					{isEspanol ? "Skip this step" : "Omitir este paso"}
				</Link>
			</Landing>
		</>
	);
};

export default FirstStepPage;

import React, { useRef } from "react";
import AnimatedSeries from "./animated-series";

// different interfaces for child components to render
interface panelMessageWithImage {
	panelMessageWithImage: string;
}

interface panelMessage {
	panelMessage: string;
}

interface buttonPair {
	buttonTextTrue: string;
	buttonTextFalse: string;
}

const InvestmentOptionsWalkthrough: React.FC<{
	closeWindow: Function;
}> = ({ closeWindow }) => {
	const scrollWrapper = useRef<HTMLDivElement>(null);

	// wrapper will auto-scroll to the bottom when new messages appear.
	const scrollToBottom = () => {
		setTimeout(() => {
			if (scrollWrapper.current) {
				scrollWrapper.current.scrollTop =
					scrollWrapper.current.scrollHeight;
			}
		}, 0);
	};

	// re-usable dialogue
	const buildYourOwnPortfolioDialogue = [
		"Got it! You can build your own portfolio right here and now, but helping you is a little out of my depth.",
		{
			panelMessageWithImage: "Here's your link to continue:"
		} as panelMessageWithImage,
		"If you need info along the way, call for help from a real person at 800-547-7754."
	];

	const thanksForVisitingDialogue = [
		"OK! But if you get stuck, feel free to go back and review what's available or give us a call to talk to a real person at 800-547-7754. Thanks for visiting!"
	];

	/*
		Obj below is structured to be like a binary tree of responses contingent upon what the user clicks.
		Each obj represents a node which branches off to a selectTrue node or selectFalse node.
		If no further node on a branch, then render a 'go back' button to exit walkthrough.
	*/
	const dialogueToMap = {
		dialogue: [
			"We got you.",
			"Keep in mind that everyone's situation is different.",
			"We have information on the plan's investment options, but you should choose what makes the most sense for you.",
			"So, typically there are two main types of investors-hands-on or hands-off.",
			"A hands-on investor might be someone who prefers to build their own portfolio. Or maybe talking about investments get you totally amped!",
			"Does that sound like you?",
			{
				buttonTextTrue: "Sure does",
				buttonTextFalse: "Not really"
			} as buttonPair
		],
		selectTrue: {
			dialogue: buildYourOwnPortfolioDialogue
		},
		selectFalse: {
			dialogue: [
				"OK. A hands-off investor might be someone who just doesn't want to think about it too much right now. Or maybe investments are #NotYourThing.",
				"Does this sound more like you?",
				{
					buttonTextTrue: "Yep, that's me",
					buttonTextFalse: "No, not at all"
				} as buttonPair
			],
			selectTrue: {
				dialogue: [
					"Got it! The plan has portfolios created by (or with input from) investment professionals.",
					"Would you like more information about them?",
					{
						buttonTextTrue: "Yes",
						buttonTextFalse: "No"
					} as buttonPair
				],
				selectTrue: {
					dialogue: [
						{
							panelMessage:
								"There's a quick option that your organization set."
						} as panelMessage,
						"There are also target-date portfolio options.",
						"Would you like to hear more info about them?",
						{
							buttonTextTrue: "Yes",
							buttonTextFalse: "No"
						} as buttonPair
					],
					selectTrue: {
						dialogue: [
							"Target date portfolios are diversified investment options that are managed toward a particular date. You know - that happy date when you hope to retire or start taking money!",
							"Need more portfolio info?",
							{
								buttonTextTrue: "Yes",
								buttonTextFalse: "No"
							} as buttonPair
						],
						selectTrue: {
							dialogue: [
								"Target date investments are managed to a date and become more conservative as the target date gets closer. But remember, there's no guarantee of adequate income at or through retirement.",
								"Great! We'll highlight what you picked. Thanks for visiting!"
							]
						},
						selectFalse: {
							dialogue: thanksForVisitingDialogue
						}
					},
					selectFalse: {
						dialogue: thanksForVisitingDialogue
					}
				},
				selectFalse: {
					dialogue: thanksForVisitingDialogue
				}
			},
			selectFalse: {
				dialogue: buildYourOwnPortfolioDialogue
			}
		}
	};

	return (
		<div
			className="col-md-9 col-md-offset-1 bg-light-gray util-margin-bottom-md-30 util-padding-30 expanded chat-window"
			ref={scrollWrapper}
		>
			<button
				type="button"
				className="text-muted js-close-expanded animation-delay-25 show-on-expanded-content animated fadeInRightSmall"
				onClick={() => closeWindow()}
			>
				<img
					src="media/close.svg"
					height="35"
					width="35"
					alt="close-component"
				/>
			</button>
			<div className="col-lg-8 col-lg-offset-2">
				<img
					className="pull-left util-margin-vert-30"
					alt=""
					src="media/info.png"
				/>
				<AnimatedSeries
					dialogueTree={dialogueToMap}
					scrollWrapper={scrollToBottom}
					delay={1500}
					index={0}
					closeWindow={closeWindow}
				/>
			</div>
		</div>
	);
};

export default InvestmentOptionsWalkthrough;

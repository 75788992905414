import React from "react";
import Modal from "components/generic/modal/modal";
import ModalHeader from "components/generic/modal/modal-header";
import ModalBody from "components/generic/modal/modal-body";
import { Link } from 'react-router-dom';

const TmrModal: React.FC<{ bindToggle: any }> = ({ bindToggle }) => (
	<Modal bindModal={bindToggle}>
		<ModalHeader bindModal={bindToggle}>
			<h2 className="modal-title">Now for the details about your managed account.</h2>
		</ModalHeader>
		<ModalBody>
            <p>You can only make one investment election per day.</p>
			<p className="util-padding-20 bg-light-gray" style={{overflowY: 'scroll', height: '15rem'}}>
            To start the Target My Retirement<sup>&reg;</sup> service, Morningstar Investment Management LLC will need some information from you, such as your name, age, date of birth, contact information, and account balance and type. To keep it simple, we can pull this information from your current records. 
            By submitting, you’re consenting to share this information whenever you access the Morningstar Investment Management,
            Target My Retirement service from your account with recordkeeping by Principal<sup>&reg;</sup>.
             We’ll autofill your information into Target My Retirement, and you can review and edit the information through 
             Morningstar Investment Management. Once you’ve elected Target My Retirement and you want to see performance, 
             objectives and fees related to all investment options that may be used for your account under Target My Retirement, 
             go to the Morningstar Investment Management service.

            To decline setting up a Target My Retirement account, please select  "No thanks".
             If you decline, you can choose this service at a later time and will not be able to move forward at 
             this time.

            For more information, refer to the Morningstar Investment Management disclosure brochure, fees, 
            and participation agreement.

            Please note that the Target My Retirement fee of 0.XX% is in addition to the investment option 
                and recordkeeping fees. 
			</p>
            <div className="checkbox styled-checkbox util-margin-vert-30">
                <input
                    type="checkbox"
                    id="consentCheckbox"
                />
                <label htmlFor="consentCheckbox">
                    I agree that I've read the Morningstar Investment Management disclosure brochure, and I'll pay a fee for this service.<span style={{color: "#FF0000"}}>*</span>
                </label>
            </div>
            <div className="row">
            <div className="col-md-7">
                <Link
                    to='#'
                    onClick={bindToggle}
                    className="btn btn-link util-margin-bottom-15"
                >
                    No thanks
                </Link>
            </div>
            <div className="col-md-5 util-padding-left-40">
                <Link
                    to="/rollovers"
                    className="btn btn-primary util-padding-horz-60 util-margin-bottom-15"
                >
                    Submit
                </Link>
            </div>
            </div>
		</ModalBody>
       
	</Modal>
);

export default TmrModal;
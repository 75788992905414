import React, { useState } from "react";
import { Link } from "react-router-dom";
import ThreeColumnLayout from "components/layout/three-column-layout";
import Content from "components/layout/content";
import RollOversRightPanel from "components/rollovers/rollovers-right-panel";

const TypeOfFunds: React.FC = () => {
	const [fundsType, setFundsType] = useState<boolean>(false);

	return (
		<ThreeColumnLayout>
			<Content>
				<h1 className="text-center util-margin-top-0">
					Do you know what type of funds you're rolling over?
				</h1>
				<p className="util-padding-vert-10">
					Roth or not? That's the question. And if you're not sure,
					that's fine. You can still keep having all the fun with this
					section.
				</p>
				<hr className="dotted" />
				<div className="table-responsive row util-margin-horz-0">
					<table className="table-bordered col-md-12">
						<tbody>
							<tr>
								<td className="util-padding-10">
									<div className="radio styled-radio">
										<input
											type="radio"
											id="not-sure"
											name="type-of-funds"
											onChange={() => setFundsType(true)}
										/>
										<label htmlFor="not-sure">
											I'm not sure
										</label>
									</div>
								</td>
							</tr>
							<tr>
								<td className="util-padding-10">
									<div className="radio styled-radio">
										<input
											type="radio"
											id="pre-tax"
											name="type-of-funds"
											onChange={() => setFundsType(true)}
										/>
										<label htmlFor="pre-tax">
											Pre-tax only
										</label>
									</div>
								</td>
							</tr>
							<tr>
								<td className="util-padding-10">
									<div className="radio styled-radio">
										<input
											type="radio"
											id="roth-or-after-tax"
											name="type-of-funds"
											onChange={() => setFundsType(true)}
										/>
										<label htmlFor="roth-or-after-tax">
											Roth or after-tax only
										</label>
									</div>
								</td>
							</tr>
							<tr>
								<td className="util-padding-10">
									<div className="radio styled-radio">
										<input
											type="radio"
											id="both-pre-and-after-tax"
											name="type-of-funds"
											onChange={() => setFundsType(true)}
										/>
										<label htmlFor="both-pre-and-after-tax">
											Both pre-tax and after-tax
										</label>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<hr className="dotted util-margin-vert-40" />
				<div className="text-center">
					{fundsType ? (
						<Link
							to="/rollovers/money-coming-from"
							className="btn btn-primary"
						>
							Let's roll!
						</Link>
					) : (
						<button
							type="button"
							className="btn btn-default"
							disabled
						>
							Let's roll!
						</button>
					)}
				</div>
			</Content>
			<RollOversRightPanel>
				<h2 className="h3">How do I know if I have a Roth account?</h2>
				<p>Hint: It might be on your statement.</p>
				<p>
					If any of the money you want to roll over is Roth, you'll
					need to know:
				</p>
				<ul>
					<li>
						The amount of Roth contributions, not including any
						earnings/losses
					</li>
					<li>The amount of earnings on Roth contributions</li>
					<li>The tax year the first Roth contribution was made</li>
				</ul>
			</RollOversRightPanel>
		</ThreeColumnLayout>
	);
};

export default TypeOfFunds;

// date-related functions

const currentDate = () => {
	const today = new Date();

	return (
		String(today.getMonth() + 1).padStart(2, "0") +
		"/" +
		String(today.getDate()).padStart(2, "0") +
		"/" +
		today.getFullYear()
	);
};

const roundQuarterlyDate = () => {
	const currentDate = new Date();

	if (currentDate.getMonth() <= 2) {
		return `12/31/${currentDate.getFullYear()}`;
	} else if (currentDate.getMonth() <= 5) {
		return `03/31/${currentDate.getFullYear()}`;
	} else if (currentDate.getMonth() <= 8) {
		return `06/30/${currentDate.getFullYear()}`;
	} else {
		return `09/30/${currentDate.getFullYear()}`;
	}
};

const daysInMonth = (month: string) => {
	const totalDays = new Date(2019, parseInt(month), 0).getDate();
	let daysArr = [];
	for (let i = 1; i <= totalDays; i++) {
		daysArr.push(i);
	}

	return daysArr;
};

const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December"
];

const currentYear = () => {
	const today = new Date();

	return (
		today.getFullYear()
	);
};

export { currentDate, roundQuarterlyDate, daysInMonth, months, currentYear };

import { useLayoutEffect } from "react";

export function useHorizon() {
	useLayoutEffect(
		() => {
			let script = document.createElement('script');
			script.src = "horizon.min.js";
			script.async = true;
			document.body.appendChild(script);
		}
	);
}
import React, { ReactNode, createRef } from "react";
import ReactDOM from "react-dom";
import "./modal.scss";

const Modal: React.FC<{
	large?: boolean;
	bindModal: Function;
	children: ReactNode;
}> = ({ large = false, bindModal, children }) => {
	const modalRef = createRef<HTMLDivElement>();

	const closeModalFromOutsideClick = (e: React.SyntheticEvent) => {
		if (modalRef.current === e.target) bindModal();
	};

	return ReactDOM.createPortal(
		<div
			className="modal-background"
			tabIndex={-1}
			role="dialog"
			aria-labelledby="1"
			ref={modalRef}
			onClick={e => closeModalFromOutsideClick(e)}
		>
			<div
				className={`modal fade in modal-dialog modal-fade ${large &&
					"modal-lg"}`}
				role="document"
			>
				<div className="modal-content">{children}</div>
			</div>
		</div>,
		document.body
	);
};

export default Modal;

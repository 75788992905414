import React, { ReactNode, useEffect } from "react";
import { withRouter } from "react-router-dom";

const ScrollToTop: React.FC<{ children?: ReactNode; location: any }> = ({
	children,
	location
}) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	return <>{children}</>;
};

export default withRouter(ScrollToTop);

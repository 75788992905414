import React from "react";
import ReactDOM from "react-dom";
import App from "./router";
import { StateProvider } from "./context/global-state";

ReactDOM.render(
	<StateProvider>
		<App />
	</StateProvider>,
	document.getElementById("root")
);

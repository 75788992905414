import React from "react";
import Modal from "components/generic/modal/modal";
import ModalHeader from "components/generic/modal/modal-header";
import ModalBody from "components/generic/modal/modal-body";
import Tooltip from "components/generic/tooltip/tooltip";
import { roundQuarterlyDate } from "utility/dates";

const PortfolioSnapshotModal: React.FC<{ bindToggle: Function }> = ({
	bindToggle
}) => (
	<Modal large={true} bindModal={bindToggle}>
		<ModalHeader bindModal={bindToggle}>
			<h1 className="modal-title">Portfolio Snapshot</h1>
			<h2 className="h3 util-margin-vert-0">
				Principal LifeTime Strategic Income Separate Account
			</h2>
		</ModalHeader>

		<ModalBody>
			<div className="text-right small">
				Performance shown is quarterly
				<br />
				Average Annual Total Returns through {roundQuarterlyDate()}
			</div>
			<div className="row">
				<div className="col-xs-12 card-single">
					<table className="table">
						<thead>
							<tr>
								<th style={{ width: 20 }}></th>
								<th className="hidden-sm">
									<em>Inv Manager or Sub-Advisor</em>
									<br />
									Investment Option
								</th>
								<th>
									Share Price /&nbsp;
									<span className="text-nowrap">
										Unit Value
									</span>
								</th>
								<th className="performance-results-col">
									Year To Date
								</th>
								<th className="performance-results-col">
									Since Inception<sup>1</sup>
								</th>
								<th className="performance-results-col">
									1 Month
								</th>
								<th className="performance-results-col">
									3 Month
								</th>
								<th className="performance-results-col">
									1 Year
								</th>
								<th className="performance-results-col">
									3 Year
								</th>
								<th className="performance-results-col">
									5 Year
								</th>
								<th className="performance-results-col">
									10 Year
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="asset asset-4 util-padding-10 hide-on-prebuilt">
									<span className="sr-only">
										Balanced/Asset Allocation
									</span>
									<span className="visible-sm">
										<em>Multiple Sub-Advisors</em>
										<br />
										<Tooltip classes="btn-link text-bold">
											Principal LifeTime Strategic Income
											Separate Account
										</Tooltip>
									</span>
								</td>
								<td className="hidden-sm">
									<em>Multiple Sub-Advisors</em>
									<br />
									<Tooltip classes="btn-link text-bold">
										Principal LifeTime Strategic Income
										Separate Account
									</Tooltip>
								</td>
								<td data-heading="Share Price / Unit Value">
									<div className="card-heading">
										Share Price / Unit Value
									</div>
									<span className="inner">22.4116848</span>
								</td>
								<td>
									<div className="card-heading">
										Year To Date
									</div>
									<span className="inner">8.83</span>
								</td>
								<td>
									<div className="card-heading">
										Since Inception
									</div>
									<span className="inner">4.52</span>
								</td>
								<td>
									<div className="card-heading">1 Month</div>
									<span className="inner">2.44</span>
								</td>
								<td>
									<div className="card-heading">3 Month</div>
									<span className="inner">3.03</span>
								</td>
								<td>
									<div className="card-heading">1 Year</div>
									<span className="inner">6.09</span>
								</td>
								<td>
									<div className="card-heading">3 Year</div>
									<span className="inner">4.85</span>
								</td>
								<td>
									<div className="card-heading">5 Year</div>
									<span className="inner">3.39</span>
								</td>
								<td>
									<div className="card-heading">10 Year</div>
									<span className="inner">6.29</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<span>
				Please see important disclosures on the&nbsp;
				<Tooltip classes="btn-link text-bold">
					Investment Performance and Fees
				</Tooltip>
				&nbsp;page.
			</span>
			<p>1. Performance since inception date is 03/30/2001</p>
		</ModalBody>
	</Modal>
);

export default PortfolioSnapshotModal;

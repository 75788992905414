import React, { useState, useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { StateContext } from "context/global-state";
import AnimatedMessage from "./animated-message";
import PortfolioSnapshotModal from "components/modals/portfolio-snapshot-modal";

const AnimatedPanel: React.FC<{ panelMessage: string }> = ({
	panelMessage
}) => {
	const { state } = useContext(StateContext);
	const [showModal, toggleModal] = useState<boolean>(false);

	const memoizedToggleModal = useCallback(() => {
		toggleModal(!showModal);
	}, [showModal]);

	return (
		<>
			<div className="d-flex-col">
				<AnimatedMessage message={panelMessage} />
				<div className="conversation-panel">
					<div className="panel-heading blue-bg text-white">
						<div className="util-margin-20">
							<div className="panel-title">
								<h2 className="text-white">
									Principal LifeTime Strategic Income Separate
									Account
								</h2>
							</div>
							<p>Multiple Sub-Advisors</p>
						</div>
					</div>
					<div className="panel-body text-center">
						<Link
							to={state.experienced ? "/dashboard" : "/rollovers"}
							className="btn btn-lg btn-default util-margin-20"
						>
							Yep, I want this one
						</Link>
						<button
							className="btn btn-link text-nowrap"
							onClick={() => toggleModal(true)}
							type="button"
						>
							Portfolio Snapshot
						</button>
					</div>
				</div>
			</div>
			{showModal && (
				<PortfolioSnapshotModal bindToggle={memoizedToggleModal} />
			)}
		</>
	);
};

export default AnimatedPanel;

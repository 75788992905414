import React, { ReactNode } from "react";
import Logout from "components/layout/logout";
import { Link } from "react-router-dom";
import Footer from "components/layout/footer";
import "scss/_common-styles.scss";

const Landing: React.FC<{
	children: ReactNode;
}> = props => (
	<>
		<div className="container-fluid bg-midnight util-padding-md-60 util-padding-xs-15">
			<div className="row util-padding-horz-10">
				<Link
					to="/"
					className="principal-logo util-margin-bottom-md-60 col-xs-6 col-md-3"
				>
					<img
						src="media/logo-white.svg"
						className="principal-logo-image-white"
						alt="principal-logo"
					 />
				</Link>
				<div className="col-md-offset-5 col-md-4 col-xs-6 hidden-print text-right util-margin-top-xs-5 util-margin-top-15">
					<Logout classes="util-margin-left-5 text-white pull-right" languageClasses="text-white btn btn-link btn-sm" />
				</div>
			</div>
			<div className="row">
				<div className="col-sm-6 col-sm-offset-3 col-xs-12 text-center">
					{props.children}
				</div>
			</div>
		</div>
		<Footer />
	</>
);

export default Landing;

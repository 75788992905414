import React, { useContext, useState, useCallback, useEffect } from "react";
import { StateContext } from "context/global-state";
import ThreeColumnLayout from "components/layout/three-column-layout";
import Content from "components/layout/content";
import RightPanel from "components/layout/right-panel";
import Tooltip from "components/generic/tooltip/tooltip";
import Card from "components/layout/card";
import { Link } from "react-router-dom";
import { currentDate } from "utility/dates";
import PortfolioSnapshotModal from "components/modals/portfolio-snapshot-modal";
import "scss/dashboard.scss";

const DashboardPage: React.FC = () => {
	const { state, dispatch } = useContext(StateContext);
	const [showModal, toggleModal] = useState<boolean>(false);
	const [showCompletedItems, toggleCompletedItems] = useState<boolean>(false);
	const memoizedToggleModal = useCallback(() => {
		toggleModal(!showModal);
	}, [showModal]);
	useEffect(() => {
		dispatch({
			type: "updateExperience",
			payload: true
		});
	}, [dispatch]);
	const isEspanol = state.language === "Español";

	return (
		<ThreeColumnLayout>
			<Content>
				<>
					<h1 className="text-center text-midnight h2">
						{isEspanol ? "You made it!" : "¡Lo lograste!"}
					</h1>
					<p>
						{isEspanol ?
							"You did great and your reward is making it to your Dashboard. That's fun! This is where you'll see what you did and other things you could do to keep setting up your account. Plus, there's more info about saving for retirement."
							:
							"¡Bien hecho! Ya pronto verás tu recompensa en el panel. ¡Qué genial! Aquí verás todo tu progreso y lo que puedes hacer para seguir configurando tu cuenta. Además, hay más información sobre los ahorros para la jubilación."
						}
					</p>
				</>
				<hr className="dotted" />
				<div className="util-margin-top-50 row">
					<div className="col-md-5">
						<div className="sm-text-center">
							<img
								src="media/flag.svg"
								style={{
									height: 90,
									width: 90
								}}
								alt="flag-icon"
							/>
						</div>
						<h3 className="text-midnight">
							{isEspanol ? "Working toward your savings goals" : "Trabaja hacia tus metas de ahorro."}
						</h3>
					</div>
					<div className="col-md-7">
						<div className="util-margin-bottom-30 small">
							<button
								className="btn-link text-muted text-bold"
								onClick={() =>
									toggleCompletedItems(!showCompletedItems)
								}
							>
								<i
									className={`fa ${
										showCompletedItems
											? "fa-angle-up"
											: "fa-angle-down"
									} util-margin-right-15`}
								/>
								{showCompletedItems ?
									(<>
										{isEspanol ? "Hide completed items" : "Ocultar elementos completados"}
									</>)
									:
									(<>
										{isEspanol ? "View completed items" : "Ver los elementos completados"}
									</>)
								}
							</button>
						</div>

						<ul
							className="onboarding-dashboard-links"
							style={{
								display: showCompletedItems ? "block" : "none",
								marginBottom: 0
							}}
						>
							<li>
								<Link
									to="/contributions/set-custom-contribution-rate"
									className="onboarding-dashboard-link"
								>
									{isEspanol ? "Set or review contribution rate" : "Configurar o revisar mi tasa de contribución"}
								</Link>
							</li>
							<li>
								<Link
									to="/investments"
									className="onboarding-dashboard-link"
								>
									{isEspanol ? "Set or review investment options" : "Configurar o revisar las opciones de inversión"}
								</Link>
							</li>
							<li>
								<Link
									to="/rollovers"
									className="onboarding-dashboard-link"
								>
									{isEspanol ? "Rollover other retirement savings" : "Traspasar otros ahorros de jubilación"}
								</Link>
							</li>
							<li>
								<Link
									to="/contributions/annual-increase"
									className="onboarding-dashboard-link"
								>
									{isEspanol ? "Set or review annual increase" : "Configurar o revisar el incremento anual"}
								</Link>
							</li>
						</ul>
						<ul className="onboarding-dashboard-links">
							<li>
								<Tooltip
									position="left"
									classes="btn-link onboarding-dashboard-link text-bold"
								>
									{isEspanol ? "Add or review beneficiary" : "Agregar o revisar beneficiarios (en inglés)"}
								</Tooltip>
							</li>
							<li>
								<Tooltip
									position="left"
									classes="btn-link onboarding-dashboard-link text-bold"
								>
									{isEspanol ? "Set communication preferences" : "Configurar preferencias de comunicación (en inglés)"}
								</Tooltip>
							</li>
						</ul>
					</div>
				</div>
				<hr className="dotted" />
				<div className="row util-margin-top-50">
					<div className="col-md-5">
						<div className="sm-text-center">
							<img
								src="media/dashboard-icon.svg"
								style={{
									height: 90,
									width: 90
								}}
								alt="dashboard-icon"
							/>
						</div>
						<h2
							className="text-midnight h3"
							id="landing-page-available-title"
						>
							{isEspanol ? "Account access" : "Acceso a la cuenta"}
						</h2>
					</div>
					<div className="col-md-7">
						<div className="callout dashboard-callout bg-light-blue">
							<p>
								{isEspanol ?
									"If you're feeling ambitious, check out your account overview page to see your balance and other retirement resources."
									:
									"Si quieres hacer incluso más, revisa la página del resumen de tu cuenta para ver tu saldo y otros recursos para la jubilación. (en inglés)"
								}
							</p>
							<div>
								<Tooltip classes="btn btn-primary">
									{isEspanol ? "Take me there" : "Llévame allí"}
								</Tooltip>
							</div>
						</div>
					</div>
				</div>
				<hr className="dotted" />
				<div className="row util-margin-top-50">
					<div className="col-md-5">
						<div className="sm-text-center">
							<img
								src="media/contributions-icon.svg"
								style={{
									height: 90,
									width: 90
								}}
								alt="contribution-icon"
							/>
						</div>
						<h2
							className="text-midnight h3"
							id="contributions-summary-title"
						>
							{isEspanol ? "Bravo!" : "Buena elección."}
						</h2>
						<p id="contributions-summary-description">
							{isEspanol ?
								"Way to make your retirement a priority! Your commitment to saving is impressive."
								:
								"Cada pasito que das con tu cuenta de jubilación es muy importante."
							}
						</p>
					</div>
					<div className="col-md-7">
						<div className="callout dashboard-callout bg-light-blue">
							<div className="row">
								<div className="col-xs-7">
									<p className="text-bold">
										{isEspanol ? "Contribution amount" : "Total de contribuciones"}
									</p>
								</div>
								<div className="col-xs-5 text-right">
									<p className="text-bold">
										{state.contributionRates.total}%
									</p>
								</div>
							</div>
							<div className="row util-margin-top-15">
								<div className="col-xs-7">
									<p className="text-bold">{isEspanol ? "Pre-tax" : "Antes de impuestos"}</p>
								</div>
								<div className="col-xs-5 text-right">
									<p className="text-bold">
										{state.contributionRates.preTax}%
									</p>
								</div>
							</div>
							{isEspanol &&
								<p className="util-margin-top-10">
									Increasing <span>{state.annualIncreases.preTax[0]}%</span> annually up to <span>{state.annualIncreases.preTax[1]}%</span>
								</p>
							}
							<div className="row util-margin-top-15">
								<div className="col-xs-7">
									<p className="text-bold">{isEspanol ? "Roth" : "Roth Después de impuestos"}</p>
								</div>
								<div className="col-xs-5 text-right">
									<p className="text-bold">
										{state.contributionRates.total -
											state.contributionRates.preTax}
										%
									</p>
								</div>
							</div>
							{isEspanol &&
								<p className="util-margin-top-10">
									Increasing <span>{state.annualIncreases.postTax[0]}%</span> annually up to <span>{state.annualIncreases.postTax[1]}%</span>
								</p>
							}
							<Link
								to="/contributions/set-custom-contribution-rate"
								className="btn btn-small btn-default util-margin-vert-10"
							>
								{isEspanol ? "Edit" : "Editar"}
							</Link>
						</div>
					</div>
				</div>
				<hr className="dotted" />
				<div className="row util-margin-top-50">
					<div className="col-md-5">
						<div className="sm-text-center">
							<img
								src="media/investments-icon.svg"
								style={{
									height: 90,
									width: 90
								}}
								alt="investment-icon"
							/>
						</div>
						<h2 className="text-midnight h3">
							{isEspanol ? "Here's where your contributions will go" : "Aquí irán tus contribuciones"}:
						</h2>
					</div>
					<div className="col-md-7">
						<div className="callout dashboard-callout bg-light-blue">
							<div className="row">
								<div className="col-xs-8">
									<p className="text-bold">
										Principal LifeTime Strategic Income Separate Account
									</p>
								</div>
								<div className="col-xs-4 text-right">100%</div>
							</div>
							<p className="util-margin-top-10">
								<button
									className="button btn-link util-padding-0 text-bold"
									onClick={() => toggleModal(true)}
								>
									{isEspanol ? "Portfolio Snapshot" : "Un vistazo a tu portafolio (en inglés)"}
								</button>
							</p>
							<Link
								to="/investments/investment-options"
								className="btn btn-small btn-default util-margin-vert-10"
							>
								{isEspanol ? "Change" : "Cambio"}
							</Link>
						</div>
					</div>
				</div>
				<p className="text-muted">
					{isEspanol ?
						"Changes made after the market closes (3 p.m. CT), or on weekends and holidays, will be processed the following business day. You will not be able to make additional changes to how your paycheck contributions are invested until these are effective."
						:
						"Cualquier cambio que hagas después del cierre del mercado (3 p.m., hora central) o durante fines de semana o días feriados se procesará el siguiente día hábil. No se podrán hacer cambios adicionales sobre cómo se invierten tus aportes de nómina hasta que estas elecciones entren en vigor."
					}
				</p>
				<hr className="dotted" />
				<div className="row util-margin-top-50">
					<div className="col-md-5">
						<div className="sm-text-center">
							<img
								src="media/rollovers-icon.svg"
								style={{
									height: 90,
									width: 90
								}}
								alt="rollovers-icon"
							/>
						</div>
						<h2 className="text-midnight h3">{isEspanol ? "Things are rolling" : "Vamos progresando"}</h2>
					</div>
					<div className="col-md-7">
						<div className="callout dashboard-callout bg-light-blue">
							<div className="row">
								<div className="col-xs-6">
									<p className="text-bold">{isEspanol ? "Roll-in amount" : "Cantidad del traspaso"}</p>
								</div>
								<div className="col-xs-6">
									<p className="text-bold">$20,000.00</p>
								</div>
							</div>
							<div className="row util-margin-top-15">
								<div className="col-xs-6">
									<p className="text-bold">{isEspanol ? "Initiation date" : "Fecha de inicio"}</p>
								</div>
								<div className="col-xs-6">
									<span>{currentDate()}</span>
								</div>
							</div>
							<div className="row util-margin-top-15">
								<div className="col-xs-6">
									<p className="text-bold">{isEspanol ? "Status" : "Estado"}</p>
								</div>
								<div className="col-xs-6">
									<span>{isEspanol ? "In-Progress" : "à En proceso"}</span>
								</div>
							</div>
						</div>
						<small>
							<Link
								to="/rollovers"
								className="btn-link cta-text"
							>
								{isEspanol ? "Rollover another account" : "Traspasar otra cuenta"}
							</Link>
						</small>
						<hr />
					</div>
				</div>
			</Content>
			<RightPanel minHeight={2001}>
				{isEspanol ?
					(<>
						<hr />
						<h2 className="h3 util-margin-top-30">Read and review</h2>
						<ul className="list-unstyled">
							<Card
								title="Mark your calendar with these important financial deadlines"
								thumbnail="media/card_5.jpg"
								alt="Important financial deadlines"
							/>
							<Card
								title="Saving at every stage: Tips for your family’s financial plan"
								thumbnail="media/card_6.jpg"
								alt="Tips for your family's financial plan"
							/>
							<Card
								title="Leaving a legacy: Reviewing your beneficiaries"
								thumbnail="media/card_7.jpg"
								alt="Leaving a legacy: Reviewing your beneficiaries"
							/>
						</ul>
						<h2 className="h3 util-margin-top-30">Watch to feel wiser</h2>
						<ul className="list-unstyled">
							<Card
								title="5‐steps to organizing personal finances"
								thumbnail="media/card_8.jpg"
								alt="Organizing personal finances"
							/>
						</ul>
					</>)
					:
					(<>
						<h3>Construye tu futuro</h3>
						<img
							src="media/coffee.jpeg"
							alt="Coffee"
							width="100%"
						/>
						<div>
							<Tooltip
								classes="btn-link"
								position="bottom"
							>
								Es hora de decirle “hola” al futuro de tus finanzas
								</Tooltip>
						</div>
					</>)
				}
			</RightPanel>
			{showModal && (
				<PortfolioSnapshotModal bindToggle={memoizedToggleModal} />
			)}
		</ThreeColumnLayout>
	);
};

export default DashboardPage;

import React, { useState, useContext, useCallback } from "react";
import { StateContext } from "context/global-state";
import CurrencyInput from "components/layout/currency-input";
import "scss/contributions/edit-salary.scss";

const EditSalary: React.FC<{ bindToggle: Function }> = ({ bindToggle }) => {
	const { state, dispatch } = useContext(StateContext);
	const [salary, updateSalary] = useState<number>(state.income.salary);
	const [hourlyWage, updateHourlyWage] = useState<number>();
	const [hoursPerWeek, updateHoursPerWeek] = useState<number>();
	const [payFrequency, updatePayFrequency] = useState<number>(
		state.income.payFrequency
	);
	const [hourly, toggleWageType] = useState<boolean>(false);
	const memoizedUpdateSalary = useCallback(input => {
		updateSalary(parseFloat(input.replace(/[$,]/g, "")));
	}, []);
	const memoizedUpdateHourlyWage = useCallback(input => {
		updateHourlyWage(parseFloat(input.replace(/[$,]/g, "")));
	}, []);

	const frequencySelections = [
		["Weekly", 52],
		["Every other week", 24],
		["Twice a month", 26],
		["Monthly", 12]
	];

	const convertToSalary = (wage: number, weeklyHours: number) =>
		wage * (weeklyHours / 40) * 2000;

	const updateSalaryGlobal = () => {
		dispatch({
			type: "updateIncome",
			payload: {
				salary: !hourly
					? salary
					: convertToSalary(hourlyWage || 0, hoursPerWeek || 0),
				payFrequency,
				hourly
			}
		});

		bindToggle();
	};

	return (
		<div className="col-md-7 col-md-offset-1 bg-light-gray util-margin-bottom-md-30 util-padding-top-60 expanded">
			<div
				id="retirement-calc"
				className="show-on-expanded util-padding-bottom-30 row"
				style={{ display: "block" }}
			>
				<button
					type="button"
					className="text-muted js-close-expanded animation-delay-25 show-on-expanded-content animated fadeInRightSmall"
					style={{ display: "block" }}
					onClick={() => bindToggle()}
				>
					<img
						src="media/close.svg"
						height="35"
						width="35"
						alt="close-component"
					/>
				</button>
				<div className="col-lg-8 col-lg-offset-2">
					<div
						className="show-on-expanded-content animation-delay-50 text-center animated fadeInRightSmall"
						style={{ display: "block" }}
					>
						<h1>Get a better picture of your retirement</h1>
						<hr className="dotted" />
					</div>
					<div
						className="show-on-expanded-content animation-delay-100 text-center util-margin-bottom-30 animated fadeInRightSmall"
						style={{ display: "block" }}
					>
						<p className="h3">
							{!hourly
								? "Enter your annual salary to see how your decision will impact your paycheck and retirement income."
								: "Enter your hourly rate and hours per week to see how your decision will impact your paycheck and retirement income."}
						</p>
						<button
							type="button"
							className="btn btn-link util-margin-top-0"
							onClick={() => toggleWageType(!hourly)}
						>
							{!hourly
								? `Paid hourly? Try our hourly pay calculator.`
								: `Know your salary? Try our annual pay calculator.`}
						</button>
						<div className="row util-margin-top-15">
							{!hourly && (
								<CurrencyInput
									bindField={memoizedUpdateSalary}
									valueState={salary}
								/>
							)}

							{hourly && (
								<>
									<CurrencyInput
										bindField={memoizedUpdateHourlyWage}
										valueState={hourlyWage}
										placeholder="Enter hourly rate"
									/>
									<div className="col-lg-6 col-lg-offset-3 input-prefix annual-salary util-margin-top-15">
										<input
											type="number"
											min="0"
											placeholder="Enter hours per week"
											className="form-control"
											onChange={(e: any) =>
												updateHoursPerWeek(
													Number(e.target.value)
												)
											}
											value={hoursPerWeek}
										/>
									</div>
								</>
							)}
						</div>
					</div>

					<fieldset
						className="show-on-expanded-content radio-options animation-delay-150 util-margin-bottom-30 animated fadeInRightSmall"
						style={{ display: "block" }}
					>
						<legend className="hzn-radiocheckbox-legend">
							How often do you receive a paycheck?
						</legend>
						{frequencySelections.map((frequency, i) => (
							<div
								className="radio styled-radio-btn old"
								onClick={() => {
									updatePayFrequency(Number(frequency[1]));
								}}
								key={`paymentFrequency-${i}`}
							>
								<input
									type="radio"
									name="frequency-option"
									checked={payFrequency === frequency[1]}
									readOnly
								/>
								<label>
									<span>{frequency[0]}</span>
								</label>
							</div>
						))}
					</fieldset>

					<div
						className="show-on-expanded-content animation-delay-200 text-center animated fadeInRightSmall"
						style={{ display: "block" }}
					>
						<button
							type="button"
							className="btn btn-primary btn-lg"
							onClick={() => updateSalaryGlobal()}
						>
							Calculate
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditSalary;

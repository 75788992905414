import React, { ReactNode } from "react";
import LeftNavPanel from "components/layout/left-nav-panel";
import TopNav from "components/layout/top-nav";
import Footer from "components/layout/footer";
import "scss/contributions/set-contribution-rate.scss";

const ThreeColumnLayout: React.FC<{
	children: ReactNode;
}> = ({ children }) => (
	<>
		<div className="container">
			<TopNav />
			<div className="row">
				<LeftNavPanel />
				{children}
			</div>
		</div>
		<Footer />
	</>
);

export default ThreeColumnLayout;

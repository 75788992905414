import React, { createContext, useContext, useReducer } from 'react';
import { initialState, IState, reducer } from "./reducer";

interface IContextProps {
	state: IState;
	dispatch: (action: any) => void;
}

export const StateContext = createContext({} as IContextProps);

export const StateProvider = (props: any) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	return (
		<StateContext.Provider value={{ state, dispatch }}>
			{props.children}
		</StateContext.Provider>
	);
};

export const useStateValue = () => useContext(StateContext);
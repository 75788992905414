import React, { useRef, useEffect } from "react";
import NumberFormat from "react-number-format";

const CurrencyInput: React.FC<{
	bindField: Function;
	valueState: number | undefined;
	placeholder?: string;
}> = ({ bindField, valueState, placeholder }) => {
	const inputWrapper = useRef<HTMLDivElement>(null);

	// Add custom style to inputs for library dependency
	useEffect(() => {
		inputWrapper.current &&
			inputWrapper.current.children[0].tagName === "INPUT" &&
			inputWrapper.current.children[0].classList.add("form-control");
	}, []);

	return (
		<div
			className="col-lg-6 col-lg-offset-3 input-prefix annual-salary"
			ref={inputWrapper}
		>
			<NumberFormat
				value={valueState}
				displayType="input"
				thousandSeparator={true}
				prefix="$"
				fixedDecimalScale={true}
				decimalScale={2}
				allowNegative={false}
				placeholder={placeholder}
				onChange={(e: any) => bindField(e.target.value)}
			/>
		</div>
	);
};

export default CurrencyInput;

import React from "react";
import Tooltip from "components/generic/tooltip/tooltip";

const Card: React.FC<{
	title: string;
	thumbnail: string;
	alt: string;
}> = ({ title, thumbnail, alt }) => (
	<li>
		<Tooltip position="left" classes="btn btn-link util-padding-0">
			<img src={thumbnail} className="img-responsive" alt={alt} />
			<p className="util-margin-0 text-left">
				<small>{title}</small>
			</p>
		</Tooltip>
		<hr />
	</li>
);

export default Card;

import React from "react";
import Modal from "components/generic/modal/modal";
import ModalHeader from "components/generic/modal/modal-header";
import ModalBody from "components/generic/modal/modal-body";

const PreTaxContributionModal: React.FC<{ bindToggle: any }> = ({
	bindToggle
}) => (
	<Modal bindModal={bindToggle}>
		<ModalHeader bindModal={bindToggle}>
			<h2 className="modal-title">Pre-tax contribution</h2>
		</ModalHeader>
		<ModalBody>
			<ul>
				<li>
					You typically don't pay income taxes on the money when you
					contribute.
				</li>
				<li>You do pay income tax when you withdraw it.</li>
			</ul>
		</ModalBody>
	</Modal>
);

export default PreTaxContributionModal;

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { StateContext } from "context/global-state";

const Logout: React.FC<{ classes?: string; languageClasses: string }> = props => {
	const {state, dispatch} = useContext(StateContext);
	const toggleLanguage = () => {
		if (state.language === "Español") {
			dispatch({
				type: "setLanguage",
				payload: "English",
			});
		} else {
			dispatch({
				type: "setLanguage",
				payload: "Español",
			})
		}
	}
	return (
		<>
			<button
				className={props.languageClasses}
				onClick={toggleLanguage}
			>
				{state.language}
			</button>
			{state.language === "Español" ? "Welcome " : "Bienvenido "}
			John -
			<Link
				className={props.classes}
				to="/"
				style={{ paddingTop: 3 }}
			>
				{state.language === "Español" ? " Logout" : " Cerrar sesión"}
			</Link>
		</>
	);
};

export default Logout;

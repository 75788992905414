import React, { useEffect } from "react";
import "scss/money-on-the-move.scss";

const MoneyOnTheMovePage: React.FC<{ history: any }> = ({ history }) => {
	useEffect(() => {
		setTimeout(() => {
			if (history.location.pathname === "/money-on-the-move")
				history.push("/dashboard");
		}, 3000);
	}, [history]);

	return (
		<div className="container">
			<div className="row">
				<img
					src="media/cropped-egg.gif"
					className="centered-gif"
					alt=""
				/>
			</div>
		</div>
	);
};

export default MoneyOnTheMovePage;

import React from "react";
import Logout from "components/layout/logout";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import "scss/_common-styles.scss";

const TopNav: React.FC<{ history?: any }> = ({ history }) => (
	<div
		id="top-nav"
		className="util-padding-vert-xs-10 util-padding-top-md-10 util-margin-bottom-40"
	>
		{history.location.pathname !== "/create-your-account" && (
			<div className="row">
				<div className="logout col-xs-3 col-md-offset-9 hidden-print text-right util-margin-top-xs-5 util-margin-top-15">
					<Logout languageClasses="btn btn-link btn-sm" />
				</div>
			</div>
		)}
		<div className="row util-margin-top-15">
			<Link to="/" className="col-md-3 hidden-sm" id="principal-logo">
				<img
					src="media/logo-onecolor-right.svg"
					alt="principal p logo"
					width="150px"
					className="principal-logo"
				/>
			</Link>
			{history.location.pathname !== "/create-your-account" && (
				<div className="col-md-offset-5 col-md-4 hidden-sm text-right">
					<img
						className="sample-company"
						alt="sample-company"
						src="media/sample-group.png"
					/>
					<p className="util-margin-top-10 plan-info-section">
						SAMPLE GROUP DEFINED CONTRIBUTION PLAN
					</p>
				</div>
			)}
		</div>
	</div>
);

export default withRouter(TopNav);

import React from "react";
import { Link } from "react-router-dom";
import ThreeColumnLayout from "components/layout/three-column-layout";
import Content from "components/layout/content";
import RollOversRightPanel from "components/rollovers/rollovers-right-panel";
import "scss/rollovers/money-coming-from.scss";

const MoneyComingFrom: React.FC = () => {
	const savingsAccounts = [
		"Fidelity Investments",
		"Empower",
		"Vanguard",
		"Voya",
		"Prudential",
		"Charles Schwab",
		"John Hancock",
		"Mass Mutual",
		"T Rowe Price",
		"Wells Fargo"
	];
	const kabob = (item: string) => item.replace(/ /g, "-").toLowerCase();
	return (
		<ThreeColumnLayout>
			<Content>
				<h1 className="text-center util-margin-top-0">
					Where's the money coming from?
				</h1>
				<p>
					First, tell us where your retirement savings are at right
					now and we'll help you with instructions on how to get the
					money.
				</p>
				<hr className="dotted util-margin-vert-40" />
				<div className="row util-margin-bottom-10">
					<div className="col-md-10">
						<input type="text" className="form-control" />
					</div>
					<div className="col-md-2">
						<Link
							to="/rollovers/enter-your-phone"
							className="btn btn-primary"
						>
							Go!
						</Link>
					</div>
				</div>

				<ul className="money-from-links savings-accounts">
					{savingsAccounts.map(account => (
						<li key={kabob(account)}>
							<Link
								to="/rollovers/enter-your-phone"
								className="util-padding-left-0"
							>
								{account}
							</Link>
						</li>
					))}
				</ul>
				<div className="text-center">
					<Link
						to="/rollovers/enter-your-phone"
						className="btn btn-link"
					>
						No luck searching? Not sure? That's OK, we got you
					</Link>
				</div>
				<hr className="dotted util-margin-top-20" />
			</Content>
			<RollOversRightPanel>
				<h2 className="h3">Why is this important?</h2>
				<p className="util-margin-bottom-40">
					It helps us give you the instructions specific to your
					situation and to make things a little easier for you knowing
					what to expect.
				</p>
			</RollOversRightPanel>
		</ThreeColumnLayout>
	);
};

export default MoneyComingFrom;

import React, { FunctionComponent, Fragment } from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import { useHorizon } from "hooks/useHorizon";
import ScrollToTop from "components/layout/scroll-to-top";
import LoginPage from "pages/login";
import CreateYourAccount from "pages/create-your-account";
import FirstStepPage from "pages/contributions/first-step";
import SetContributionRatePage from "pages/contributions/set-contribution-rate";
import AllocateContributionPage from "pages/contributions/allocate-contribution";
import InvestmentsFirstStep from "pages/investments/first-step";
import InvestmentsQuickOption from "pages/investments/quick-option";
import RolloversFirstStep from "pages/rollovers/first-step";
import HowMuchToRoll from "pages/rollovers/how-much-to-roll";
import Check from "pages/rollovers/check";
import TypeOfFunds from "pages/rollovers/type-of-funds";
import MoneyComingFrom from "pages/rollovers/money-coming-from";
import EnterYourPhone from "pages/rollovers/enter-your-phone";
import GetYourCheck from "pages/rollovers/get-your-check";
import AnnualIncreasePage from "pages/contributions/annual-increase";
import ReviewContributionPage from "pages/contributions/review-contribution";
import OneLastThing from "pages/rollovers/one-last-thing";
import Confirmation from "pages/rollovers/confirmation";
import DashboardPage from "pages/dashboard";
import InvestmentOptions from "pages/investments/investment-options";
import HowAboutThis from "pages/rollovers/how-about-this";
import SetCustomContributionRatePage from "pages/contributions/set-custom-contribution-rate";
import MoneyOnTheMovePage from "pages/money-on-the-move";
import InvestmentTMR from "pages/investments/investment-tmr";


// Miscellaneous routes that don't belong to a section
const generalRoutes = [
	[LoginPage, "/"],
	[CreateYourAccount, "/create-your-account"],
	[DashboardPage, "/dashboard"],
	[MoneyOnTheMovePage, "/money-on-the-move"]
];

// Routes for investment section
const investmentRoutes = [
	[InvestmentsFirstStep, "/investments"],
	[InvestmentsQuickOption, "/investments/quick-option"],
	[InvestmentOptions, "/investments/investment-options"],
	[InvestmentTMR, "/investments/investment-tmr"]
];

// Routes for rollover section
const rolloverRoutes = [
	[RolloversFirstStep, "/rollovers"],
	[Check, "/rollovers/check"],
	[HowMuchToRoll, "/rollovers/how-much-to-roll"],
	[TypeOfFunds, "/rollovers/type-of-funds"],
	[EnterYourPhone, "/rollovers/enter-your-phone"],
	[GetYourCheck, "/rollovers/get-your-check"],
	[HowAboutThis, "/rollovers/how-about-this"],
	[MoneyComingFrom, "/rollovers/money-coming-from"],
	[OneLastThing, "/rollovers/one-last-thing"],
	[Confirmation, "/rollovers/confirmation"]
];

// Routes for contribution section
const contributionRoutes = [
	[FirstStepPage, "/contributions"],
	[SetContributionRatePage, "/contributions/set-contribution-rate"],
	[AllocateContributionPage, "/contributions/allocate-contribution-rate"],
	[AnnualIncreasePage, "/contributions/annual-increase"],
	[ReviewContributionPage, "/contributions/review"],
	[
		SetCustomContributionRatePage,
		"/contributions/set-custom-contribution-rate"
	]
];

const routes = [
	generalRoutes,
	investmentRoutes,
	rolloverRoutes,
	contributionRoutes
];

const App: React.FC = () => {
	useHorizon();

	return (
		<Router>
			<ScrollToTop>
				{routes.map(
					(routeCollection: any[], collectionIndex: number) => (
						<Fragment key={`collection-${collectionIndex}`}>
							{routeCollection.map(
								(
									route: FunctionComponent | string[],
									routeIndex: number
								) => (
									<Route
										key={`collection-${collectionIndex}-route-${routeIndex}`}
										exact
										path={route[1]}
										component={route[0]}
									/>
								)
							)}
						</Fragment>
					)
				)}
			</ScrollToTop>
		</Router>
	);
};

export default App;

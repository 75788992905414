import React, { useContext } from "react";
import { StateContext } from "context/global-state";
import { Formik, Form } from "formik";
import ThreeColumnLayout from "components/layout/three-column-layout";
import Content from "components/layout/content";
import RightPanel from "components/layout/right-panel";
import { withRouter } from "react-router-dom";
import "scss/create-your-account.scss";

const CreateYourAccount: React.FC<{ history: any }> = ({ history }) => {
	const { state } = useContext(StateContext);
	const redirectToContributions = () => {
		history.push(
			state.experienced ? "/money-on-the-move" : "/contributions"
		);
	};

	return (
		<ThreeColumnLayout>
			<Content>
				<img
					src="media/create-account.jpg"
					alt="mobile phone create account"
					className="mobile-phone-add-acct"
				/>
				<h1 className="text-center util-margin-top-0">
					Create your account
				</h1>
				<hr className="dotted" />
				<Formik
					initialValues={{}}
					onSubmit={redirectToContributions}
					render={() => (
						<Form>
							<div>
								<div className="form-group">
									<label className="is-required">
										First name
									</label>
									<p className="help-block">
										Be sure to use your legal first name
										here. And nice to meet you!
									</p>
									<input
										type="text"
										maxLength={100}
										id="firstName"
										className="form-control"
									/>
								</div>
								<div className="form-group">
									<label className="is-required">
										Last name
									</label>
									<p className="help-block">
										We'll need your legal last name as well.
									</p>
									<input
										type="text"
										maxLength={100}
										id="lastName"
										className="form-control"
									/>
								</div>
								<div className="form-group">
									<label className="is-required">
										Date of birth
									</label>
									<p className="help-block">
										Dotting the 'i's and crossing the 't's.
									</p>
									<div className="input-group date">
										<input
											type="text"
											maxLength={14}
											placeholder="MM/DD/YYYY"
											data-input-mask-type="date"
											className="form-control horizon-date-picker horizon-input-mask"
											data-provide="horizon-date-picker"
											data-horizon-date-picker-end-date="0d"
										/>
										<span className="input-group-addon">
											<i className="fa fa-calendar"></i>
										</span>
									</div>
								</div>
								<div className="form-group">
									<label className="is-required">
										Social Security Number, Privacy ID, or
										Alternate ID
									</label>
									<p className="help-block">
										If your employer provided you with a
										Privacy or Alternate ID, use it here. If
										not, use your SSN. No dashes or spaces,
										please.
									</p>
									<input
										type="text"
										maxLength={20}
										id="ssn"
										className="form-control"
									/>
								</div>
								<div className="form-group">
									<label className="is-required">
										Primary zip code
									</label>
									<input
										type="text"
										maxLength={5}
										id="zipCode"
										className="form-control"
									/>
								</div>
								<div className="checkbox styled-checkbox util-margin-vert-30">
									<input
										type="checkbox"
										id="consentCheckbox"
									/>
									<label htmlFor="consentCheckbox">
										I consent to doing business
										electronically.
									</label>
								</div>
								<button
									type="submit"
									className="btn btn-primary center-block util-margin-vert-30"
								>
									Continue
								</button>
							</div>
						</Form>
					)}
				/>
			</Content>
			<RightPanel minHeight={1280}>
				<h2 className="h3 text-bold text-success">
					We'll help protect your info
				</h2>
				<p>
					We take personal information and security very seriously.
					The questions we ask and information we gather are guarded
					carefully and are only used for security purposes.
				</p>
			</RightPanel>
		</ThreeColumnLayout>
	);
};

export default withRouter(CreateYourAccount);

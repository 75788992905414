import React from "react";

const AnimatedGoBack: React.FC<{
	closeWindow: Function;
}> = ({ closeWindow }) => {
	return (
		<>
			<div className="conversation-content-back text-center animated fadeInUpSmall">
				<button
					className="btn btn-default"
					onClick={() => closeWindow()}
				>
					<i className="fa fa-chevron-left"></i>
					Back to your choices
				</button>
			</div>
		</>
	);
};

export default AnimatedGoBack;

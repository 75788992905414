import React from "react";
import Tooltip from "components/generic/tooltip/tooltip";

const ChatPanel: React.FC<{ bindChatPanel: Function }> = ({
	bindChatPanel
}) => {
	return (
		<div
			className="bg-white panel panel-default container-tab"
			style={{
				borderColor: "#e6e6e6",
				borderWidth: 1,
				borderStyle: "solid"
			}}
		>
			<div className="panel-heading bg-primary">
				<h2 className="panel-title util-inlineBlock">Live chat</h2>
				<button
					type="button"
					className="btn btn-danger btn-sm pull-right"
					onClick={() => bindChatPanel()}
				>
					End chat
				</button>
			</div>
			<div className="panel-body util-margin-5 util-padding-bottom-40">
				<div className="util-margin-bottom-10 pull-left chat-wrapper">
					<strong>Bob Thoms</strong>
					<p className="bg-light-blue util-margin-bottom-0 util-padding-10 chat-border">
						Thanks for contacting Principal. I'm Bob Thoms, how can
						I help today?
					</p>
					<small>10:23 AM</small>
				</div>
				<div className="util-margin-bottom-10 message pull-right chat-wrapper">
					<strong className="pull-right">Me</strong>
					<br />
					<p className="bg-white util-margin-bottom-0 util-padding-10 chat-border">
						I have a question about rolling additional funds into my
						retirement account. Can you help me with that?
					</p>
					<small className="pull-right">10:23 AM</small>
				</div>
				<div className="util-margin-bottom-10 pull-left chat-wrapper">
					<strong>Bob Thoms</strong>
					<p className="bg-light-blue util-margin-bottom-0 util-padding-10 chat-border">
						I would be more than happy to help you. What are your
						questions?
					</p>
					<small>10:24 AM</small>
				</div>
			</div>
			<p className="util-margin-left-10">For illustrative purpose only</p>
			<div>
				<hr className="util-margin-top-0" />
				<p className="text-muted util-padding-10">
					Write a message...
					<Tooltip
						classes="btn btn-success btn-sm pull-right"
						position="left"
					>
						Send
					</Tooltip>
				</p>
			</div>
		</div>
	);
};

export default ChatPanel;

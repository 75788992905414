import React, { useContext } from "react";
import DefaultAltButtonLink from "components/generic/buttons/default-alt-button-link";
import Landing from "components/layout/landing";
import { StateContext } from "context/global-state";

const Confirmation: React.FC = () => {
	const { state } = useContext(StateContext);
	const isEspanol = state.language === "Español";
	return (
		<>
			<Landing>
				<img
					src="media/checkmark.svg"
					alt="checkmark-icon"
					height="240"
				/>
				<h1>
					{isEspanol ?
						"You did it! Feels good, doesn't it?"
						:
						"¡Lo lograste! Se siente bien, ¿no?"
					}
				</h1>
				<p>
					{isEspanol ?
						"Now, let's see what else you can do."
						:
						"Ahora, veamos qué más puedes hacer."
					}
				</p>
				<div className="util-margin-vert-40">
					<DefaultAltButtonLink route="/dashboard">
						{isEspanol ? "Check out your dashboard" : "Revisa el panel de tu cuenta"}
					</DefaultAltButtonLink>
				</div>
			</Landing>
		</>
	);
};

export default Confirmation;

import React, { useContext, ReactNode } from "react";
import { StateContext } from "context/global-state";

const ModalFooter: React.FC<{ children: ReactNode, bindModal: Function }> = ({ children, bindModal }) => {
	const { state } = useContext(StateContext);
	const isEspanol = state.language === "Español";

	return (
		<div className="modal-footer">
			<button
				type="button"
				className="btn btn-link pull-left"
				aria-label="Close"
				onClick={() => bindModal()}
			>
				<span aria-hidden>{isEspanol ? "Go back" : "Regresar"}</span>
			</button>
			{children}
		</div>
	)
};

export default ModalFooter;

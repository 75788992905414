import React, { useEffect, useState, useRef, useCallback } from "react";
import AnimatedMessage from "./animated-message";
import AnimatedButtons from "./animated-buttons";
import AnimatedGoBack from "./animated-go-back";
import AnimatedPanel from "./animated-panel";
import AnimatedPanelWithImage from "./animated-panel-with-image";

const recurse = (
	dialogueTree: any,
	scrollWrapper: Function,
	delay: number,
	index: number,
	closeWindow: Function
) => {
	return (
		<AnimatedSeries
			dialogueTree={dialogueTree}
			scrollWrapper={scrollWrapper}
			delay={delay}
			index={index} // ensure unique keys when mapped
			closeWindow={closeWindow}
		/>
	);
};

const AnimatedSeries: React.FC<{
	dialogueTree: any;
	scrollWrapper: Function;
	delay: number;
	index: number;
	closeWindow: Function;
}> = ({ dialogueTree, scrollWrapper, delay, index, closeWindow }) => {
	const { dialogue, selectTrue, selectFalse } = dialogueTree;
	const [textToRender, concatText] = useState<any[]>([]);
	const [counter, updateCounter] = useState<number>(0);
	const [renderNextSeries, updateRenderNextSeries] = useState<
		[boolean, boolean]
	>([false, false]);
	const [renderBackButton, updateRenderBackButton] = useState<boolean>(false);

	const memoizedRenderTrueNode = useCallback(() => {
		updateRenderNextSeries([true, true]);
	}, []);
	const memoizedRenderFalseNode = useCallback(() => {
		updateRenderNextSeries([true, false]);
	}, []);

	const useInterval = (callback: any, delay: number) => {
		const savedCallback = useRef();

		useEffect(() => {
			savedCallback.current = callback;
		}, [callback]);

		useEffect(() => {
			if (delay != null) {
				let id = setInterval(() => {
					// @ts-ignore: Unreachable code error
					let intervalCounter = savedCallback.current();
					scrollWrapper();
					if (intervalCounter >= dialogue.length - 1) {
						clearInterval(id);
						if (!selectFalse && !selectTrue) {
							updateRenderBackButton(true);
						}
					}
					return;
				}, delay);
				return () => clearInterval(id);
			}
			return;
		}, [delay]);
	};

	useInterval(() => {
		concatText(textToRender.concat([dialogue[counter]]));
		updateCounter(counter + 1);
		return counter;
	}, delay);

	return (
		<>
			{textToRender.map((message: any, i: number) => {
				if (message.panelMessageWithImage) {
					return (
						<AnimatedPanelWithImage
							key={`animated-panel-with-image-${index}-${i}`}
							panelMessageWithImage={
								message.panelMessageWithImage
							}
						/>
					);
				} else if (message.panelMessage) {
					return (
						<AnimatedPanel
							key={`animated-panel-${index}-${i}`}
							panelMessage={message.panelMessage}
						/>
					);
				} else if (message.buttonTextTrue) {
					return (
						<AnimatedButtons
							key={`animated-buttons-${index}-${i}`}
							buttonTextTrue={message.buttonTextTrue}
							buttonTextFalse={message.buttonTextFalse}
							buttonTextClickTrue={memoizedRenderTrueNode}
							buttonTextClickFalse={memoizedRenderFalseNode}
						/>
					);
				} else {
					return (
						<AnimatedMessage
							key={`animated-message-${index}-${i}`}
							message={message}
						/>
					);
				}
			})}
			{renderBackButton && <AnimatedGoBack closeWindow={closeWindow} />}
			{renderNextSeries[0] &&
				(renderNextSeries[1]
					? recurse(
							selectTrue,
							scrollWrapper,
							delay,
							index + 1,
							closeWindow
					  )
					: recurse(
							selectFalse,
							scrollWrapper,
							delay,
							index + 1,
							closeWindow
					  ))}
		</>
	);
};

export default AnimatedSeries;

import React, { useState, useEffect, useContext } from "react";
import { StateContext } from "context/global-state";
import NumberFormat from "react-number-format";

const ContributionPerPayCheck: React.FC = () => {
	const { state } = useContext(StateContext);
	const { income, contributionRates } = state;
	const [contributionPerPayCheck, setContributionPerPayCheck] = useState<
		number
	>(0);

	useEffect(() => {
		return setContributionPerPayCheck(
			(income.salary * contributionRates.total) /
				100 /
				income.payFrequency
		);
	}, [contributionRates.total, income.salary, income.payFrequency]);

	return (
		<>
			<p className="util-margin-top-30">Contribution per paycheck</p>
			<p className="h3 util-margin-bottom-15 util-margin-top-0 text-bold">
				<NumberFormat
					value={contributionPerPayCheck}
					displayType="text"
					thousandSeparator={true}
					prefix="$"
					fixedDecimalScale={true}
					decimalScale={2}
				/>
			</p>
		</>
	);
};

export default ContributionPerPayCheck;

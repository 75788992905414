import React from "react";
import Tooltip from "components/generic/tooltip/tooltip";
import {currentYear} from "utility/dates";

const Footer: React.FC = () => (
	<>
		<section className="footer-row footer">
			<nav>
				<ul className="nav nav-inline">
					<li>
						<Tooltip classes="footer-link">Terms of Use</Tooltip>
					</li>
					<li>
						<Tooltip classes="footer-link">Disclosures</Tooltip>
					</li>
					<li>
						<Tooltip classes="footer-link">Privacy</Tooltip>
					</li>
					<li>
						<Tooltip classes="footer-link">Security</Tooltip>
					</li>
					<li>
						<Tooltip classes="footer-link">Report Fraud</Tooltip>
					</li>
					<li>
						<Tooltip classes="footer-link">FAQ</Tooltip>
					</li>
				</ul>
			</nav>
			<div className="footer-legal">
				<p>&copy;{currentYear()}, Principal Financial Services, Inc.</p>
				<p>
					Securities offered through Principal Securities, Inc.,
					member <Tooltip classes="link-font">SIPC</Tooltip>
				</p>
			</div>
		</section>
	</>
);

export default Footer;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import ThreeColumnLayout from "components/layout/three-column-layout";
import Content from "components/layout/content";
import RollOversRightPanel from "components/rollovers/rollovers-right-panel";

const Check: React.FC = () => {
	const [checkHandy, setCheckHandy] = useState<boolean>(false);

	return (
		<ThreeColumnLayout>
			<Content>
				<h1 className="text-center util-margin-top-0">
					Already have a check handy?
				</h1>
				<hr className="dotted" />
				<div className="table-responsive row util-margin-horz-0">
					<table className="table-bordered col-md-12">
						<tbody>
							<tr>
								<td className="util-padding-10">
									<div className="radio styled-radio">
										<input
											type="radio"
											id="radio-yes"
											name="check-handy"
											onChange={() => setCheckHandy(true)}
										/>
										<label htmlFor="radio-yes">
											<strong>Yes</strong> - Awesome. Send
											that check on to Principal.
										</label>
									</div>
								</td>
							</tr>
							<tr>
								<td className="util-padding-10">
									<div className="radio styled-radio">
										<input
											type="radio"
											id="radio-no"
											name="check-handy"
											onChange={() => setCheckHandy(true)}
										/>
										<label htmlFor="radio-no">
											<strong>No</strong> - That's OK.
											We'll help you through the process.
										</label>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<hr className="dotted util-margin-vert-40" />
				<div className="text-center">
					{checkHandy ? (
						<Link
							to="/rollovers/type-of-funds"
							className="btn btn-primary"
						>
							Next
						</Link>
					) : (
						<button
							type="button"
							className="btn btn-default"
							disabled
						>
							Next
						</button>
					)}
				</div>
			</Content>
			<RollOversRightPanel>
				<h2 className="h3">Got a check?</h2>
				<p>
					Having a check can speed up the process, but it's not a big
					deal if you don't yet.
				</p>
				<p className="util-margin-bottom-25">
					If your check isn't made out to Principal, that's OK. Call
					us and we'll help.
				</p>
			</RollOversRightPanel>
		</ThreeColumnLayout>
	);
};

export default Check;

import React from "react";
import "./buttons.scss";

// Buttons with '+' and '-' icons for incrementing and decrementing
const IncrementButton: React.FC<{
	increment: boolean; // determines what icon to use
	handleIncrement: Function; // handles bound function
	id?: string; // html id to use
}> = ({ increment, handleIncrement, id }) => (
	<button
		id={id}
		className="bg-white increment-decrement-btn text-midnight util-padding-left-sm-10 util-padding-right-md-10"
		onClick={() => handleIncrement()}
	>
		<i className={`fa ${increment ? "fa-plus" : "fa-minus"}`} />
	</button>
);

export default IncrementButton;

import React, { ReactNode, useState, createRef } from "react";
import "./tooltip.scss";

export interface ITooltip {
	position?: "top" | "left" | "right" | "bottom";
	children: ReactNode;
	classes?: string;
}

const Tooltip: React.FC<ITooltip> = ({
	position = "top",
	children,
	classes
}) => {
	const [displayTooltip, toggleTooltip] = useState<boolean>(false);
	const tooltipRef = createRef<HTMLDivElement>();
	const tooltipWrapperRef = createRef<HTMLDivElement>();
	const createDisplay = () => {
		if (tooltipWrapperRef.current && tooltipRef.current) {
			tooltipWrapperRef.current.style.height = `${tooltipRef.current.clientHeight}px`;
			tooltipWrapperRef.current.style.width = `${tooltipRef.current.clientWidth}px`;
		}
		toggleTooltip(true);
	};

	return (
		<span
			className="nodemo"
			ref={tooltipWrapperRef}
			onMouseOut={() => toggleTooltip(false)}
		>
			{displayTooltip && (
				<div className={`nodemo-tooltip tooltip-${position}`}>
					<div className="tooltip-message">
						This feature is not available in this demo.
					</div>
				</div>
			)}
			<span
				className={`nodemo-trigger ${classes}`}
				onMouseOver={() => createDisplay()}
				ref={tooltipRef}
			>
				{children}
			</span>
		</span>
	);
};

export default Tooltip;
